import { Pressable } from 'react-native'
import { useLink } from 'solito/link'

export function NiceLink({ href, as, onPress: userOnPress, ...props }) {
  const { onPress, ...linkProps } = useLink({
    href,
    as,
  })
  const combinedOnPress = (e) => {
    try {
      e.preventDefault()
    } catch (err) {}
    onPress()
    if (userOnPress) userOnPress()
  }

  return <Pressable {...props} {...linkProps} onPress={combinedOnPress} />
}
