import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M12 16.2C13.7673 16.2 15.2 14.7673 15.2 13C15.2 11.2327 13.7673 9.8 12 9.8C10.2327 9.8 8.8 11.2327 8.8 13C8.8 14.7673 10.2327 16.2 12 16.2Z"
        fill={color}
      />
      <Path
        d="M9 3L7.17 5H4C3.47005 5.00158 2.96227 5.2128 2.58753 5.58753C2.2128 5.96227 2.00158 6.47005 2 7V19C2.00158 19.5299 2.2128 20.0377 2.58753 20.4125C2.96227 20.7872 3.47005 20.9984 4 21H20C20.5299 20.9984 21.0377 20.7872 21.4125 20.4125C21.7872 20.0377 21.9984 19.5299 22 19V7C21.9984 6.47005 21.7872 5.96227 21.4125 5.58753C21.0377 5.2128 20.5299 5.00158 20 5H16.83L15 3H9ZM12 18C11.0111 18 10.0444 17.7068 9.22215 17.1573C8.3999 16.6079 7.75904 15.827 7.3806 14.9134C7.00216 13.9998 6.90315 12.9945 7.09607 12.0245C7.289 11.0546 7.7652 10.1637 8.46447 9.46447C9.16373 8.7652 10.0546 8.289 11.0245 8.09607C11.9945 7.90315 12.9998 8.00216 13.9134 8.3806C14.827 8.75904 15.6079 9.3999 16.1573 10.2221C16.7068 11.0444 17 12.0111 17 13C17 14.3261 16.4732 15.5979 15.5355 16.5355C14.5979 17.4732 13.3261 18 12 18Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'CameraIcon'

export const CameraIcon = memo<IconProps>(themed(Icon))
