// Note: Username is moved to Auth0 univesal screen
// Retaining this screen in case that changes
import { Button, DelayedTextField, Text } from '@bp/ui'
import { useState } from 'react'
import API from 'app/api'
import { useAccountInfoStore } from 'app/store'
import React from 'react'
import { useOnboardingStore } from '../store'
import { OnboardingStep } from './OnboardingStep'

export function OnboardingUsernameStep() {
  const username = useAccountInfoStore((store) => store.username)

  const [newUsername, setNewUsername] = useState(username || '')
  const [error, setError] = useState('')
  const [continueEnabled, setContinueEnabled] = useState(!!username)
  const currentIndex = useOnboardingStore((s) => s.currentIndex)
  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const saveUsername = useOnboardingStore((s) => s.saveUsername)

  const minLength = 4
  const maxLength = 15

  const validateUsername = (value) => {
    setContinueEnabled(false)

    if (!value) {
      setError('Please enter a username')
      return false
    }

    const newUsername = value.trim()

    const usernameFormat = /^[a-zA-Z0-9_-]+$/g
    if (!usernameFormat.test(newUsername)) {
      setError(`Username can only contain letters, numbers, underscores, and hyphens`)
      return false
    }

    if (newUsername.length < minLength || newUsername.length > maxLength) {
      setError(`Username must be between ${minLength} and ${maxLength} characters long`)
      return false
    }

    setError('')
    setContinueEnabled(true)
    return true
  }

  const onCheckUsernameExists = async (value) => {
    if (!validateUsername(value)) {
      return
    }

    try {
      setContinueEnabled(false)
      const response = await API.account.ifUsernameExists(value)
      if (response.message) {
        setError('This username has already been taken')
        return
      }
      setNewUsername(value)
      setContinueEnabled(true)
    } catch (e) {
      setContinueEnabled(false)
      setError('Error checking username')
    }
  }

  const handleSave = async () => {
    if (username !== newUsername) {
      saveUsername({ value: newUsername, index: currentIndex })
    }
    showNextStep()
  }

  return (
    <OnboardingStep
      title={'Create Your Account Credentials'}
      description1={
        'Pick a username that will be your unique identifier and allow you to interact with others on the platform.'
      }
    >
      <DelayedTextField
        placeholder="Your username"
        maxLength={30}
        accessibilityLabel="Your username"
        onChangeText={onCheckUsernameExists}
        value={newUsername}
      />
      {error ? <Text error>{error}</Text> : null}
      <Button variant="primary" onPress={handleSave} disabled={!continueEnabled}>
        Continue
      </Button>
    </OnboardingStep>
  )
}
