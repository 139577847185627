import { YStack, Text } from '@bp/ui'
export function OnboardingStepDescription({ text }) {
  return (
    <YStack fg={1} fs={1}>
      <Text variant="body" centered>
        {text || '<OnboardingStepDescription>'}
      </Text>
    </YStack>
  )
}
