import { differenceInYears, parse, formatDistanceToNow, parseISO } from 'date-fns'

export function calculateAge(dob: string): number {
  const date = parse(dob, 'yyyy-MM-dd', new Date())
  const age = differenceInYears(new Date(), date)
  return age
}

export function timeAgo(datetime) {
  return formatDistanceToNow(parseISO(datetime), { addSuffix: true })
}
