import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M8.48674 0.00010375C8.26191 0.00362544 8.04767 0.096388 7.89102 0.258033C7.73438 0.419677 7.64813 0.636997 7.65121 0.862297V1.28758H2.98286C2.87042 1.28605 2.75881 1.30692 2.65449 1.34898C2.55017 1.39104 2.45523 1.45345 2.37518 1.53258C2.29513 1.61171 2.23157 1.706 2.18819 1.80995C2.1448 1.91391 2.12246 2.02546 2.12246 2.13814H0.86088C0.748405 2.13655 0.636736 2.15737 0.532362 2.1994C0.427987 2.24143 0.33299 2.30383 0.252889 2.38297C0.172789 2.46211 0.109184 2.55642 0.0657703 2.6604C0.0223565 2.76439 0 2.87599 0 2.98871C0 3.10143 0.0223565 3.21303 0.0657703 3.31701C0.109184 3.421 0.172789 3.51531 0.252889 3.59445C0.33299 3.67359 0.427987 3.73599 0.532362 3.77801C0.636736 3.82004 0.748405 3.84087 0.86088 3.83927H16.1391C16.2516 3.84087 16.3633 3.82004 16.4676 3.77801C16.572 3.73599 16.667 3.67359 16.7471 3.59445C16.8272 3.51531 16.8908 3.421 16.9342 3.31701C16.9776 3.21303 17 3.10143 17 2.98871C17 2.87599 16.9776 2.76439 16.9342 2.6604C16.8908 2.55642 16.8272 2.46211 16.7471 2.38297C16.667 2.30383 16.572 2.24143 16.4676 2.1994C16.3633 2.15737 16.2516 2.13655 16.1391 2.13814H14.8775C14.8775 2.02546 14.8552 1.91391 14.8118 1.80995C14.7684 1.706 14.7049 1.61171 14.6248 1.53258C14.5448 1.45345 14.4498 1.39104 14.3455 1.34898C14.2412 1.30692 14.1296 1.28605 14.0171 1.28758H9.34879V0.862297C9.35035 0.748501 9.3291 0.635548 9.28631 0.530129C9.24351 0.42471 9.18005 0.328967 9.09966 0.248571C9.01928 0.168175 8.92362 0.104759 8.81833 0.0620801C8.71305 0.0194012 8.60029 -0.0016736 8.48674 0.00010375ZM0.86088 5.5404L2.38274 18.4982C2.48289 19.3547 3.20638 20 4.06706 20H12.9329C13.7936 20 14.5163 19.3547 14.6173 18.4982L16.1391 5.5404H0.86088Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'TrashIcon'

export const TrashIcon = memo<IconProps>(themed(Icon))
