import { OnboardingPermissionsStep } from './OnboardingPermissionsStep'
import PermissionImage from '../images/contacts.png'

export function OnboardingContactsPermissionsStep() {
  return (
    <OnboardingPermissionsStep
      title={'Share Your Contact'}
      description1={'Allow us to access your contact information for personalized experiences.'}
      permission="Contacts"
      image={PermissionImage}
    />
  )
}
