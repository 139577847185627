import React, { memo } from 'react'
import type { IconProps } from '@tamagui/helpers-icon'
import { Svg, Circle as _Circle, Path, Text as _Text } from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...otherProps}>
      <Path
        d="M17.9025 9.99703V8.00299H9.99703V0.0975342H8.00299V8.00299H0.0975342V9.99703H8.00299V17.9025H9.99703V9.99703H17.9025Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'PlusIcon'

export const PlusIcon = memo<IconProps>(themed(Icon))
