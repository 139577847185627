import { Button, Text, Spinner, Avatar, XStack, YStack, PlusIcon, ScrollView } from '@bp/ui'
import { useEffect, useState } from 'react'
import API from 'app/api'
import * as Contacts from 'expo-contacts'
import React from 'react'
import { useOnboardingStore } from '../store'
import { OnboardingStep } from './OnboardingStep'
import { Platform } from 'react-native'

export function OnboardingFollowRecommendationsStep({ previousStep, nextStep, step }) {
  const [suggestedUsers, setSuggestedUsers] = useState(null)
  const [suggestedUsersWithContacts, setSuggestedUsersWithContacts] = useState(null)
  const [allowContacts, setAllowContacts] = useState(false)
  const [contacts, setContacts] = useState<Contacts.Contact[]>([])
  const [contactsPermissionStatus, setContactsPermissionStatus] =
    useState<Contacts.PermissionStatus>()

  const showNextStep = useOnboardingStore((s) => s.showNextStep)

  useEffect(() => {
    if (allowContacts) {
      const contactsList = contacts.map((contact) => contact)
      API.user
        .syncContacts(contactsList)
        .then(setSuggestedUsersWithContacts)
        .catch((_) => setSuggestedUsers([]))
    } else {
      API.user
        .getSuggestedUsers(5)
        .then(setSuggestedUsers)
        .catch((_) => setSuggestedUsers([]))
    }
  }, [allowContacts, contacts])

  const requestContactsPermission = async () => {
    const { status } = await Contacts.requestPermissionsAsync()
    setContactsPermissionStatus(status)
    if (status === 'granted') {
      const { data } = await Contacts.getContactsAsync({
        fields: [Contacts.Fields.PhoneNumbers, Contacts.Fields.Emails],
      })

      if (data.length > 0) {
        setContacts(data)
      }
    }
  }

  const [followed, setFollowed] = useState([])
  const handleFollow = (suggested) => (e) => {
    try {
      if (followed.includes(suggested?.id)) {
        API.user.unfollow(suggested?.id)
        setFollowed((followed) => followed.filter((fid) => fid !== suggested?.id))
      } else {
        API.user.follow(suggested.id)
        setFollowed((followed) => [...followed, suggested?.id])
      }
    } catch (e) {}
  }

  const suggestedList = allowContacts ? suggestedUsersWithContacts : suggestedUsers

  useEffect(() => {
    requestContactsPermission()
  }, [])

  return (
    <OnboardingStep
      title={'Follow Recommended Accounts'}
      description1={' Here are some interesting profiles to follow. '}
      description2={'Follow the ones that interest you.'}
    >
      <YStack fg={1} gap={15}>
        <YStack>
          <Button variant="primary" onPress={showNextStep}>
            I'm done! Let's go!
          </Button>
        </YStack>
        <YStack fg={1} h={Platform.OS === 'web' ? 'auto' : 200}>
          {suggestedList ? (
            suggestedList.length > 0 ? (
              <ScrollView fg={1}>
                <YStack gap={8}>
                  {suggestedList.map((suggested) => {
                    const alreadyFollowed = followed.includes(suggested?.id)
                    return (
                      <XStack
                        ai="center"
                        jc="space-between"
                        bw={1}
                        boc="$mediumLight"
                        br="$4"
                        p="$2"
                        key={suggested?.id}
                        gap={10}
                      >
                        <Avatar
                          circular
                          size="$5"
                          accessibilityLabel="Cam"
                          src={suggested?.avatar?.sm}
                        />
                        <Text fg={1} fs={1}>
                          {suggested?.nickname}
                        </Text>
                        <Button
                          onPress={handleFollow(suggested)}
                          color={alreadyFollowed ? 'black' : 'white'}
                          variant="primary"
                          icon={PlusIcon}
                        >
                          {alreadyFollowed ? `Followed` : `Follow`}
                        </Button>
                      </XStack>
                    )
                  })}
                </YStack>
              </ScrollView>
            ) : (
              <Text centered variant="caption2" color="$medium">
                No suggested users
              </Text>
            )
          ) : (
            <YStack h={200} ai="center" jc="center">
              <Spinner />
            </YStack>
          )}
        </YStack>
      </YStack>
    </OnboardingStep>
  )
}
