import { Adapt, Select as TuiSelect, Sheet, YStack, getFontSize, styled } from 'tamagui'
import { Check, ChevronDown, ChevronUp } from '@tamagui/lucide-icons'

const BaseSelect = styled(TuiSelect, {
  name: 'BaseSelect',
})

const StyledSelect = BaseSelect.styleable(({ width, placeholder, options, ...props }, ref) => (
  <BaseSelect {...props} ref={ref}>
    <BaseSelect.Trigger width={width} iconAfter={ChevronDown}>
      <BaseSelect.Value placeholder={placeholder} />
    </BaseSelect.Trigger>
    <Adapt when="sm" platform="touch">
      <Sheet
        native={!!props.native}
        modal
        dismissOnSnapToBottom
        animationConfig={{
          type: 'spring',
          damping: 20,
          mass: 1.2,
          stiffness: 250,
        }}
      >
        <Sheet.Frame>
          <Sheet.ScrollView>
            <Adapt.Contents />
          </Sheet.ScrollView>
        </Sheet.Frame>
        <Sheet.Overlay
          animation="lazy"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          backgroundColor="$darker"
        />
      </Sheet>
    </Adapt>
    <BaseSelect.Content zIndex={200000}>
      <BaseSelect.ScrollUpButton
        alignItems="center"
        justifyContent="center"
        position="relative"
        width="100%"
        height="$3"
      >
        <YStack zIndex={10}>
          <ChevronUp size={20} />
        </YStack>
      </BaseSelect.ScrollUpButton>

      <BaseSelect.Viewport minWidth={200}>
        <BaseSelect.Group>
          {options.map((option, i) => {
            return (
              <BaseSelect.Item index={i} key={option.id} value={option.value}>
                <BaseSelect.ItemText>{option.id || option.value}</BaseSelect.ItemText>
                <BaseSelect.ItemIndicator marginLeft="auto">
                  <Check size={16} />
                </BaseSelect.ItemIndicator>
              </BaseSelect.Item>
            )
          })}
        </BaseSelect.Group>
        {/* {props.native && (
          <YStack
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            alignItems="center"
            justifyContent="center"
            width={'$4'}
            pointerEvents="none"
          >
            <ChevronDown size={getFontSize((props.size ?? '$true') as any)} />
          </YStack>
        )} */}
      </BaseSelect.Viewport>

      <BaseSelect.ScrollDownButton
        alignItems="center"
        justifyContent="center"
        position="relative"
        width="100%"
        height="$3"
      >
        <YStack zIndex={10}>
          <ChevronDown size={20} />
        </YStack>
      </BaseSelect.ScrollDownButton>
    </BaseSelect.Content>
  </BaseSelect>
))

export const Select = styled(StyledSelect, {
  name: 'Select',
})
