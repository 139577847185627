import { OnboardingPermissionsStep } from './OnboardingPermissionsStep'
import PermissionImage from '../images/location.png'

export function OnboardingLocationPermissionsStep() {
  return (
    <OnboardingPermissionsStep
      title={'Access Your Location'}
      description1={'Allow us to use your location for relevant content and services.'}
      permission="Location"
      image={PermissionImage}
    />
  )
}
