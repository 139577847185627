import { create } from 'zustand'
import { useAccountInfoStore, useUserInfoStore } from 'app/store'
import { Platform } from 'react-native'

import {
  ONBOARDING_CHOOSE_INTEREST_STEP,
  ONBOARDING_COMPLETION_STEP,
  ONBOARDING_DATE_OF_BIRTH_STEP,
  ONBOARDING_DISPLAY_NAME_STEP,
  ONBOARDING_DISPLAY_LOCATION_STEP,
  ONBOARDING_CHOOSE_GENDER_STEP,
  ONBOARDING_FOLLOW_RECOMMENDATIONS_STEP,
  ONBOARDING_LOCATION_PERMISSIONS_STEP,
  ONBOARDING_PUSH_NOTIFICATION_PERMISSIONS_STEP,
  ONBOARDING_CONTACTS_PERMISSIONS_STEP,
  ONBOARDING_PROFILE_IMAGE_STEP,
  ONBOARDING_USERNAME_STEP,
} from '../helpers'

const defaultProps = {
  currentStep: false,
  currentIndex: 0,
  steps: [],
  first: false,
  last: false,
  permissions: {
    camera: null,
    contacts: null,
    mediaLibrary: null,
    location: null,
    notifications: null,
  },
  status: null,
  pending: null,
  interests: [],
  interested: [],
}

const errorPromise = (delay = 1000) => {
  return new Promise((r) =>
    setTimeout(r, 1000, { error: { message: 'Testing error in developmet' } })
  )
}

const successPromise = (delay = 1000) => {
  return new Promise((r) => setTimeout(r, 1000, { error: { success: true } }))
}

const getStepsWithErrorState = (currentSteps, index, error) => {
  let steps = [...currentSteps]
  steps[index] = {
    ...steps[index],
    saving: false,
    saved: false,
    error,
  }
  return [...steps]
}

const getStepsWithSavingState = (currentSteps, index) => {
  let steps = [...currentSteps]
  steps[index] = {
    ...steps[index],
    saving: true,
    saved: false,
    error: false,
  }

  return [...steps]
}

const getStepsWithSavedState = (currentSteps, index) => {
  let steps = [...currentSteps]
  steps[index] = {
    ...steps[index],
    saving: false,
    saved: true,
    error: false,
  }

  return [...steps]
}

export const useOnboardingStore = create((set, get) => ({
  ...defaultProps,
  setCurrentStep: () => {},
  setSteps: async ({ dateOfBirth, username, displayName }) => {
    const permissions = get()
    let steps = []
    const defaults = { saving: false, saved: false, error: false }

    // show permissions step in native
    const canShowPermissions = Platform?.OS !== 'web'

    // DOB
    if (!dateOfBirth) {
      steps.push({ name: ONBOARDING_DATE_OF_BIRTH_STEP, ...defaults })
    }

    // Username
    if (!username) {
      steps.push({ name: ONBOARDING_USERNAME_STEP, ...defaults })
    }

    // Display name
    if (!displayName || username === displayName) {
      steps.push({ name: ONBOARDING_DISPLAY_NAME_STEP, ...defaults })
    }

    steps.push({ name: ONBOARDING_PROFILE_IMAGE_STEP, ...defaults })
    steps.push({ name: ONBOARDING_CHOOSE_GENDER_STEP, ...defaults })
    steps.push({ name: ONBOARDING_CHOOSE_INTEREST_STEP, ...defaults })

    // Location Permissions step
    const canShowLocationPermission = !permissions?.location?.granted && canShowPermissions
    //const canShowLocationPermission = canShowPermissions
    if (canShowLocationPermission) {
      steps.push({ name: ONBOARDING_LOCATION_PERMISSIONS_STEP, ...defaults })
    }

    // Location Display
    steps.push({ name: ONBOARDING_DISPLAY_LOCATION_STEP, ...defaults })

    // Push Notification permission step
    const canShowNotificationPermission = !permissions?.notifications?.granted && canShowPermissions
    //const canShowNotificationPermission = canShowPermissions
    if (canShowNotificationPermission) {
      steps.push({ name: ONBOARDING_PUSH_NOTIFICATION_PERMISSIONS_STEP, ...defaults })
    }

    // Contacts permission step
    const canShowContactsPermission = !permissions?.contacts?.granted && canShowPermissions
    //const canShowContactsPermission = canShowPermissions
    if (canShowContactsPermission) {
      steps.push({ name: ONBOARDING_CONTACTS_PERMISSIONS_STEP, ...defaults })
    }

    // Recommendations step
    steps.push({ name: ONBOARDING_FOLLOW_RECOMMENDATIONS_STEP, ...defaults })

    // Completion step
    steps.push({ name: ONBOARDING_COMPLETION_STEP, ...defaults })

    set({
      steps: [...steps],
      currentStep: steps[0].name,
      currentIndex: 0,
    })
  },
  setStep: (nextStep) => {
    const { steps } = get()
    const newStepIndex = steps.findIndex((step) => step.name === nextStep)
    set({
      currentStep: nextStep,
      currentIndex: newStepIndex,
    })
  },
  showNextStep: () => {
    const { currentStep, steps } = get()
    const currentStepIndex = steps.findIndex((step) => step.name === currentStep)
    const nextStepIndex = Math.min(currentStepIndex + 1, steps.length - 1)
    const nextStep = steps[nextStepIndex].name
    set({
      currentStep: nextStep,
      currentIndex: nextStepIndex,
    })
  },
  showPreviousStep: () => {
    const { currentStep, steps } = get()
    const currentStepIndex = steps.findIndex((step) => step.name === currentStep)
    const prevStepIndex = Math.max(currentStepIndex - 1, 0)
    const prevStep = steps[prevStepIndex].name
    set({
      currentStep: prevStep,
      currentIndex: prevStepIndex,
    })
  },
  getStepByName: (stepName) => {
    const { steps } = get()
    const stepIndex = steps.findIndex((step) => step.name === stepName)
    return steps[stepIndex]
  },
  getStepByIndex: (stepIndex) => {
    const { steps } = get()
    return steps[stepIndex]
  },
  getStepIndexByName: (stepName) => {
    const { steps } = get()
    const stepIndex = steps.findIndex((step) => step.name === stepName)
    return stepIndex
  },
  saveStep: async (index, handler) => {
    const { steps: currentSteps } = get()

    // set saving state
    let steps = getStepsWithSavingState(currentSteps, index)
    set({ steps })

    // save
    const { error } = await handler()

    // set error state
    if (error) {
      steps = getStepsWithErrorState(currentSteps, index, error)
      set({ steps })
      return
    }

    // set saved state
    steps = getStepsWithSavedState(currentSteps, index)
    set({ steps })
  },
  saveDateOfBirth: async ({ value, index }) => {
    const { saveStep } = get()
    const { setDob } = useAccountInfoStore.getState()
    const handler = () => setDob(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  saveUsername: async ({ value, index }) => {
    const { saveStep } = get()
    const { setUsername } = useAccountInfoStore.getState()
    const handler = () => setUsername(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  saveDisplayName: async ({ value, index }) => {
    const { saveStep } = get()
    const { setDisplayName } = useAccountInfoStore.getState()
    const handler = () => setDisplayName(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  saveProfileImage: async ({ value, index }) => {
    const { setAvatar } = useUserInfoStore.getState()
    const { saveStep } = get()
    const handler = () => setAvatar(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  saveGender: async ({ value, index }) => {
    const { setGender } = useAccountInfoStore.getState()
    const { saveStep } = get()
    const handler = () => setGender(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  saveInterests: async ({ value, index }) => {
    const { setInterests } = useAccountInfoStore.getState()
    const { saveStep } = get()
    const handler = () => setInterests(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  saveLocation: async ({ value, index }) => {
    const { setLocation } = useAccountInfoStore.getState()
    const { saveStep } = get()
    const handler = () => setLocation(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  saveRecommendations: async ({ value, index }) => {
    const { setRecommendations } = useAccountInfoStore.getState()
    const { saveStep } = get()
    const handler = () => setRecommendations(value)
    saveStep(index, handler)
    //saveStep(index, errorPromise)
    //saveStep(index, successPromise)
  },
  setPermissions: (newPermissions) => {
    const { permissions: currentPermissions } = get()
    set({ permissions: { ...currentPermissions, ...newPermissions } })
  },
  setStatus: (value) => {
    set({ status: value })
  },
  setPending: (value) => {
    set({ pending: value })
  },
  removeStepIndex: (index) => {
    const { steps } = get()
    const newSteps = steps.splice(index, 1)
    set({
      steps: [...newSteps],
    })
  },
  startOnboardingTest: () => {
    set({ permissions: { ...defaultProps.permissions }, interests: [], interested: [] })
  },
  setInterests: (interests) => {
    set({ interests })
  },
  setInterested: (interested) => {
    set({ interested })
  },
  clear: () => {
    set({ ...defaultProps })
  },
}))
