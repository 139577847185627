import { Input as TuiInput, InputProps, styled } from 'tamagui'
import { ExclamationCircleIcon, Text, Stack, YStack } from '../'
import { useState, useEffect } from 'react'
import type { FunctionComponent } from 'react'
import type { IconProps } from '@tamagui/helpers-icon'

type StyledTextFieldProps = {
  error?: boolean
  success?: boolean
  info?: boolean
  warning?: boolean
  helperText?: string
  label?: string
  endIcon?: IconProp
  startIcon?: IconProp
  inputProps?: object
}

type IconProp = JSX.Element | FunctionComponent<IconProps>

export type TextFieldProps = InputProps & StyledTextFieldProps

const Input = styled(TuiInput, {
  name: 'Input',
  borderWidth: '$0.5',
  backgroundColor: '$lighter',
  focusStyle: {
    outlineWidth: '$1.5',
    outlineColor: '$primaryLighter',
  },
  pressStyle: {
    outlineWidth: '$1.5',
    outlineColor: '$primaryLighter',
  },
  variants: {
    error: {
      true: {
        borderColor: '$error',
        hoverStyle: {
          borderColor: '$errorDark',
        },
        focusStyle: {
          outlineColor: '$errorLighter',
          borderColor: '$error',
        },
        pressStyle: {
          outlineColor: '$errorLighter',
          borderColor: '$error',
        },
      },
    },
    success: {
      true: {
        borderColor: '$success',
        hoverStyle: {
          borderColor: '$successDark',
        },
        focusStyle: {
          outlineColor: '$successLighter',
          borderColor: '$success',
        },
        pressStyle: {
          outlineColor: '$successLighter',
          borderColor: '$success',
        },
      },
    },
    info: {
      true: {
        borderColor: '$info',
        hoverStyle: {
          borderColor: '$infoDark',
        },
        focusStyle: {
          outlineColor: '$infoLighter',
          borderColor: '$info',
        },
        pressStyle: {
          outlineColor: '$infoLighter',
          borderColor: '$info',
        },
      },
    },
    warning: {
      true: {
        borderColor: '$warning',
        hoverStyle: {
          borderColor: '$warningDark',
        },
        focusStyle: {
          outlineColor: '$warningLighter',
          borderColor: '$warning',
        },
        pressStyle: {
          outlineColor: '$warningLighter',
          borderColor: '$warning',
        },
      },
    },
  },
})

const BaseTextField = Input.styleable(
  ({ onChangeText, inputProps, ...props }: TextFieldProps, ref) => {
    const { error, success, info, warning, helperText, label, endIcon } = props
    const { startIcon, ...cleanProps } = props

    let inputPropsCombined = inputProps || {}

    if (startIcon) {
      inputPropsCombined.paddingLeft = '$8'
    }

    if (endIcon) {
      inputPropsCombined.paddingRight = '$8'
    }

    const adornmentProps = {
      width: '$4',
      height: '$4',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    }

  const showInfo = error || success || info || warning
  const infoProps = {
    variant: 'footnote',
    error: !!error,
    success: !!success,
    info: !!info,
    warning: !!warning,
  }

  return (
    <YStack {...cleanProps}>
      {label ? <Text variant="label">{label}</Text> : null}
      <Stack>
        <Input {...cleanProps} {...inputPropsCombined} ref={ref} onChangeText={onChangeText} />
        {startIcon ? <Stack {...adornmentProps}>{startIcon}</Stack> : null}
        {!!error ? (
          <Stack {...adornmentProps} right={0}>
            <ExclamationCircleIcon color="$errorDarker" />
          </Stack>
        ) : endIcon ? (
          <Stack {...adornmentProps} right={0}>
            {endIcon}
          </Stack>
        ) : null}
      </Stack>
      {showInfo && (
        <YStack pt={5}>
          <Text {...infoProps}>{helperText}</Text>
        </YStack>
      )}
    </YStack>
  )
})

const TextField = styled(BaseTextField, {
  name: 'TextField',
})

const DelayedTextField = ({ value = '', delay = 300, onChangeText = () => {}, ...props }) => {
  const [inputValue, setInputValue] = useState(value)

  const [debouncedCallback, setDebouncedCallback] = useState(() => (...args) => {})

  useEffect(() => {
    const debounce = (callback, delay) => {
      let timeoutId
      return (...args) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
          callback(...args)
        }, delay)
      }
    }

    const debouncedHandler = debounce(onChangeText, delay)

    setDebouncedCallback(() => debouncedHandler)
  }, [])

  const handleInputChange = (value) => {
    setInputValue(value)
    debouncedCallback(value)
  }

  return <TextField value={inputValue} onChangeText={handleInputChange} {...props} />
}

export { TextField, DelayedTextField }
