import { Button, DelayedTextField } from '@bp/ui'
import { useState } from 'react'
import { useAccountInfoStore, useUserInfoStore } from 'app/store'
import React from 'react'
import { useOnboardingStore } from '../store'
import {
  ONBOARDING_DISPLAY_NAME_TEXT1,
  ONBOARDING_DISPLAY_NAME_TEXT2,
  ONBOARDING_DISPLAY_NAME_ERROR_TEXT2,
  ONBOARDING_DISPLAY_NAME_ERROR_TEXT3,
  ONBOARDING_DISPLAY_NAME_ERROR_TEXT4,
} from '../helpers'

import { OnboardingStep } from './OnboardingStep'

export function OnboardingDisplayNameStep() {
  const [error, setError] = useState(false)
  const display_name = useAccountInfoStore((s) => s.display_name)
  const username = useUserInfoStore((s) => s.username)
  const [continueEnabled, setContinueEnabled] = useState(true)
  const [newDisplayName, setNewDisplayName] = useState(display_name || username)
  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const saveDisplayName = useOnboardingStore((s) => s.saveDisplayName)
  const currentIndex = useOnboardingStore((s) => s.currentIndex)

  const validateDisplayname = (value) => {
    if (!value) {
      return ONBOARDING_DISPLAY_NAME_ERROR_TEXT2
    }

    const displayNameFormat = /^[a-zA-Z0-9_-\s!@#$^&*()+=\[\]:,.?]+$/g
    if (!displayNameFormat.test(value)) {
      return ONBOARDING_DISPLAY_NAME_ERROR_TEXT3
    }

    if (value.trim().length < 4 || value.trim().length > 50) {
      return ONBOARDING_DISPLAY_NAME_ERROR_TEXT4
    }

    return false
  }

  const onCheckDisplayname = async (value) => {
    setNewDisplayName(value)
    setError('')
    setContinueEnabled(false)

    const error = validateDisplayname(value)

    if (error) {
      setError(error)
      setContinueEnabled(false)
      return true
    }

    setContinueEnabled(true)
  }

  const handleSave = () => {
    saveDisplayName({ index: currentIndex, value: newDisplayName })
    showNextStep()
  }

  return (
    <OnboardingStep
      title={ONBOARDING_DISPLAY_NAME_TEXT1}
      description1={ONBOARDING_DISPLAY_NAME_TEXT2}
    >
      <DelayedTextField
        placeholder="Display name"
        maxLength={50}
        accessibilityLabel="Display name"
        onChangeText={onCheckDisplayname}
        value={newDisplayName}
        error={!!error}
        helperText={error}
      />
      <Button variant="primary" onPress={handleSave} disabled={!!error || !continueEnabled}>
        Continue
      </Button>
    </OnboardingStep>
  )
}
