import { YStack, Text, Button } from '@bp/ui'
import React, { useEffect } from 'react'
import { useAccountInfoStore, useUserInfoStore } from 'app/store'
import { useAuth } from 'app/lib/auth'
import { OnboardingLogo } from './OnboardingLogo'
import { useAppState } from '../hooks'

export function OnboardingPendingEmailVerificationStep() {
  const { logout } = useAuth()
  const fetchAccountInfo = useAccountInfoStore((s) => s.setAccountInfo)
  const fetchUserInfo = useUserInfoStore((s) => s.setUserInfo)

  useAppState()

  return (
    <YStack gap={25} alignItems="center">
      <YStack>
        <OnboardingLogo />
      </YStack>
      <Text variant="H4" textAlign="center">
        Please verify your account
      </Text>
      <Text textAlign="center">We've sent an email to your email address</Text>
      <Text textAlign="center">Please click the link in the email to verify your account</Text>

      <Button
        onPress={() => {
          fetchAccountInfo()
          fetchUserInfo()
        }}
        variant="link"
      >
        Already verified email?
      </Button>

      <YStack>
        <Button
          onPress={() => {
            logout()
          }}
          variant="text"
          size="$1"
          color="$medium"
        >
          Exit
        </Button>
      </YStack>
    </YStack>
  )
}
