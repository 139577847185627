import { YStack, Stack, Text, Spinner, Logo as BPLogo, Button } from '@bp/ui'
import { useAuth } from 'app/lib/auth'
import { useEffect } from 'react'
import { useRouter } from 'solito/router'
import React from 'react'

type ProtectedProps = {
  redirectTo?: string
  children: React.ReactNode
}

export default function Protected({ children, redirectTo }: ProtectedProps) {
  const { user, isLoading, login, signup, accessToken } = useAuth()

  const { push } = useRouter()

  useEffect(() => {
    if (!user && !isLoading && redirectTo) {
      push(redirectTo)
    }
  }, [redirectTo, user, isLoading])

  if ((!user || !accessToken) && isLoading) {
    return (
      <YStack bg="$light" fg={1} ai="center" jc="center">
        <YStack gap={40}>
          <Stack ai="center">
            <BPLogo scale={3} />
          </Stack>
          <Stack>
            <Spinner />
          </Stack>
        </YStack>
      </YStack>
    )
  }

  if (user && accessToken && !isLoading) {
    return <YStack fg={1}>{children}</YStack>
  }

  return (
    <YStack bg="$light" fg={1} ai="center" jc="center">
      <YStack gap={40}>
        <Stack ai="center">
          <BPLogo scale={3} />
        </Stack>
        <Stack>
          <Text variant="callout" color="$mediumDark">
            You have to be logged in to view this page
          </Text>
        </Stack>
        <Stack gap={20}>
          <Button variant="primary" onPress={login}>
            Login
          </Button>
          <Button variant="link" onPress={signup} color="$dark">
            Signup
          </Button>
        </Stack>
      </YStack>
    </YStack>
  )
}
