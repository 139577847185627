import { OnboardingPermissionsStep } from './OnboardingPermissionsStep'
import PermissionImage from '../images/media.png'

export function OnboardingImagePickerPermissionsStep() {
  return (
    <OnboardingPermissionsStep
      title={'Access Your Photos'}
      description1={'Allow us to access your photos for uploading and sharing.'}
      permission="ImagePicker"
      image={PermissionImage}
    />
  )
}
