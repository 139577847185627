import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
  Defs,
  Rect,
  ClipPath,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <G clipPath="url(#clip0_1135_277)">
        <Path
          d="M21.8889 12.2222C21.8889 17.7462 17.4114 22.2222 11.8889 22.2222C6.36646 22.2222 1.88892 17.7462 1.88892 12.2222C1.88892 6.70132 6.36646 2.22217 11.8889 2.22217C17.4114 2.22217 21.8889 6.70132 21.8889 12.2222ZM11.8889 14.2383C10.8645 14.2383 10.0341 15.0687 10.0341 16.0931C10.0341 17.1175 10.8645 17.948 11.8889 17.948C12.9133 17.948 13.7438 17.1175 13.7438 16.0931C13.7438 15.0687 12.9133 14.2383 11.8889 14.2383ZM10.1279 7.57112L10.427 13.055C10.441 13.3116 10.6532 13.5125 10.9102 13.5125H12.8677C13.1246 13.5125 13.3368 13.3116 13.3508 13.055L13.6499 7.57112C13.665 7.29394 13.4444 7.06088 13.1668 7.06088H10.611C10.3334 7.06088 10.1128 7.29394 10.1279 7.57112Z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1135_277">
          <Rect width="20" height="20" fill="white" transform="translate(1.88892 2.22217)" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

Icon.displayName = 'ExclamationCircleIcon'

export const ExclamationCircleIcon = memo<IconProps>(themed(Icon))
