import React, { useEffect } from 'react'
import { Spinner, Text } from '@bp/ui'
import { OnboardingPendingEmailVerificationStep } from './OnboardingPendingEmailVerificationStep'
import { OnboardingStepRenderer } from './OnboardingStepRenderer'
import { OnboardingWrapper } from './OnboardingWrapper'
import { useOnboardingStore } from '../store'
import { useUserInfoStore, useAccountInfoStore } from 'app/store'
import { EMAIL_STATUS } from 'app/utils'
import { getDevicePermissions } from '../helpers'

export function Onboarding() {
  const setSteps = useOnboardingStore((s) => s.setSteps)
  const steps = useOnboardingStore((s) => s.steps)
  const setPermissions = useOnboardingStore((s) => s.setPermissions)

  const isUserInfoReady = useUserInfoStore((store) => store.ready)
  const username = useUserInfoStore((store) => store.username)

  const isAccountInfoReady = useAccountInfoStore((store) => store.ready)
  const emailStatus = useUserInfoStore((store) => store.email_status)
  const dateOfBirth = useAccountInfoStore((store) => store.dob_y_m_d)
  const displayName = useAccountInfoStore((store) => store.display_name)
  const isUnverifiedEmail = emailStatus === EMAIL_STATUS.unverified

  useEffect(() => {
    if (isAccountInfoReady && isUserInfoReady && !isUnverifiedEmail) {
      const permissions = getDevicePermissions()
      setPermissions({ ...permissions })
      setSteps({
        dateOfBirth,
        username,
        displayName,
      })
    }
  }, [isAccountInfoReady, isUserInfoReady, isUnverifiedEmail])

  if (isUnverifiedEmail) {
    return (
      <OnboardingWrapper>
        <OnboardingPendingEmailVerificationStep />
      </OnboardingWrapper>
    )
  }

  if (steps.length > 0) {
    return (
      <OnboardingWrapper>
        <OnboardingStepRenderer />
      </OnboardingWrapper>
    )
  }

  return (
    <OnboardingWrapper>
      <Spinner />
    </OnboardingWrapper>
  )
}
