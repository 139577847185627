import { YStack, Text, Spinner, Logo } from '@bp/ui/src'
import { useEffect } from 'react'
import { useMedia } from '@bp/ui'
import { useAuth } from 'app/lib/auth'
// Note: user info store can be moved to /features/user/stores/userInfoStore
// Note: account info store can be moved to /features/user/stores/accountInfoStore
import { useAccountInfoStore } from 'app/store'
import { useUserInfoStore } from 'app/store'
import { ACCOUNT_STATUS } from 'app/utils'
import { UserAccountError } from './UserAccountError'

export function UserCheckPoint({ children }) {
  const { user, accessToken, isLoading } = useAuth()
  const media = useMedia()
  const gtSm = media.gtSm

  const fetchUserInfo = useUserInfoStore((store) => store.setUserInfo)
  const fetchAccountInfo = useAccountInfoStore((store) => store.setAccountInfo)
  const isAccountInfoLoading = useAccountInfoStore((store) => store.isLoading)
  const isAccountInfoReady = useAccountInfoStore((store) => store.ready)
  const isUserInfoLoading = useUserInfoStore((store) => store.isLoading)
  const isUserInfoReady = useUserInfoStore((store) => store.ready)
  const accountStatus = useAccountInfoStore((store) => store.account_status)

  // fetch user and account info after authentication
  const shouldFetchAccountInfo = !isAccountInfoLoading && !isAccountInfoReady
  const shouldFetchUserInfo = !isUserInfoLoading && !isUserInfoReady
  const shouldFetchInfo = user && accessToken && shouldFetchAccountInfo && shouldFetchUserInfo
  const guestUser = !user && !isLoading

  useEffect(() => {
    if (shouldFetchInfo) {
      fetchUserInfo()
      fetchAccountInfo()
    }
  }, [shouldFetchInfo])

  // render screen for non-authenticated
  if (guestUser) {
    return <>{children}</>
  }

  // Close account
  const isAccountClosed = accountStatus === ACCOUNT_STATUS.closed
  if (isAccountClosed) {
    return <UserAccountError text="Your account is closed" />
  }

  // Suspended users
  const isSuspendedUser = accountStatus === ACCOUNT_STATUS.suspended
  if (isSuspendedUser) {
    return <UserAccountError text="Your account is suspended" />
  }

  // Deactivated users
  const isDeactivatedUser = accountStatus === ACCOUNT_STATUS.deactivated
  if (isDeactivatedUser) {
    return <UserAccountError text="Your account is deactivated" activate />
  }

  const isInfoReady = isAccountInfoReady && isUserInfoReady

  if (!isInfoReady) {
    // Show loading screen when authentication is in progress or userinfo is loading
    return (
      <YStack fg={1} ai="center" jc="center" bc={gtSm ? '$darker' : '$light'} gap={50}>
        <Logo scale={3} />
        <Spinner size={30} />
      </YStack>
    )
  }

  // Authentication is complete and user info is loaded
  if (isInfoReady) {
    return <>{children}</>
  }

  return null
}
