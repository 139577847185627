import React from 'react'
import { YStack } from '@bp/ui'

export default function Aside() {
  return (
    <YStack gap="$6">
      <YStack elevation={3} backgroundColor={'$lightest'} width="100%" height="280px"></YStack>
      <YStack backgroundColor={'$light'} width="100%" height="280px"></YStack>
    </YStack>
  )
}
