import { Button, TextField } from '@bp/ui'
import { useEffect, useState } from 'react'
import { useAccountInfoStore } from 'app/store'
import React from 'react'
import { useOnboardingStore } from '../store'
import { OnboardingStep } from './OnboardingStep'

export function OnboardingDisplayLocationStep() {
  const [location, setLocation] = useState('')
  const [error, setError] = useState()

  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const saveLocation = useOnboardingStore((s) => s.saveLocation)
  const currentIndex = useOnboardingStore((s) => s.currentIndex)

  const getIPLocation = useAccountInfoStore((store) => store.getIPLocation)

  useEffect(() => {
    getIPLocation().then(({ city, country }) => setLocation(`${city}, ${country}`))
  }, [])

  const handleContinue = async () => {
    saveLocation({ value: location, index: currentIndex })
    showNextStep()
  }

  return (
    <OnboardingStep
      title={'Your Location'}
      description1={'Tell us your location for more relevant content and friends suggestions.'}
    >
      <TextField
        placeholder="Location"
        maxLength={50}
        accessibilityLabel="Location"
        onChangeText={setLocation}
        value={location}
        error={!!error}
        helperText={error}
      />

      <Button variant="primary" onPress={handleContinue}>
        Continue
      </Button>
    </OnboardingStep>
  )
}
