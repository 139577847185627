import { YStack, XStack } from '@bp/ui'
import {
  OnboardingChooseGenderStep,
  OnboardingChooseInterestsStep,
  OnboardingCompletionStep,
  OnboardingDateOfBirthStep,
  OnboardingDisplayLocationStep,
  OnboardingDisplayNameStep,
  OnboardingFollowRecommendationsStep,
  OnboardingPendingEmailVerificationStep,
  OnboardingProfileImageStep,
  OnboardingUsernameStep,
  OnboardingImagePickerPermissionsStep,
  OnboardingContactsPermissionsStep,
  OnboardingLocationPermissionsStep,
  OnboardingPushNotificationPermissionsStep,
} from '../components'

import {
  ONBOARDING_CHOOSE_INTEREST_STEP,
  ONBOARDING_COMPLETION_STEP,
  ONBOARDING_DATE_OF_BIRTH_STEP,
  ONBOARDING_DISPLAY_NAME_STEP,
  ONBOARDING_DISPLAY_LOCATION_STEP,
  ONBOARDING_CHOOSE_GENDER_STEP,
  ONBOARDING_FOLLOW_RECOMMENDATIONS_STEP,
  ONBOARDING_PROFILE_IMAGE_STEP,
  ONBOARDING_PENDING_EMAIL_VERIFICATION_STEP,
  ONBOARDING_USERNAME_STEP,
  ONBOARDING_IMAGE_PICKER_PERMISSIONS_STEP,
  ONBOARDING_LOCATION_PERMISSIONS_STEP,
  ONBOARDING_PUSH_NOTIFICATION_PERMISSIONS_STEP,
  ONBOARDING_CONTACTS_PERMISSIONS_STEP,
} from '../helpers'

import { useOnboardingStore } from '../store'

export function OnboardingStepRenderer() {
  const currentStep = useOnboardingStore((s) => s.currentStep)

  const ONBOARDING_STEPS = {
    [ONBOARDING_PENDING_EMAIL_VERIFICATION_STEP]: OnboardingPendingEmailVerificationStep,
    [ONBOARDING_DATE_OF_BIRTH_STEP]: OnboardingDateOfBirthStep,
    [ONBOARDING_USERNAME_STEP]: OnboardingUsernameStep,
    [ONBOARDING_DISPLAY_NAME_STEP]: OnboardingDisplayNameStep,
    [ONBOARDING_PROFILE_IMAGE_STEP]: OnboardingProfileImageStep,
    [ONBOARDING_CHOOSE_GENDER_STEP]: OnboardingChooseGenderStep,
    [ONBOARDING_CHOOSE_INTEREST_STEP]: OnboardingChooseInterestsStep,
    [ONBOARDING_DISPLAY_LOCATION_STEP]: OnboardingDisplayLocationStep,
    [ONBOARDING_FOLLOW_RECOMMENDATIONS_STEP]: OnboardingFollowRecommendationsStep,
    [ONBOARDING_COMPLETION_STEP]: OnboardingCompletionStep,
    [ONBOARDING_IMAGE_PICKER_PERMISSIONS_STEP]: OnboardingImagePickerPermissionsStep,
    [ONBOARDING_LOCATION_PERMISSIONS_STEP]: OnboardingLocationPermissionsStep,
    [ONBOARDING_PUSH_NOTIFICATION_PERMISSIONS_STEP]: OnboardingPushNotificationPermissionsStep,
    [ONBOARDING_CONTACTS_PERMISSIONS_STEP]: OnboardingContactsPermissionsStep,
  }

  if (!currentStep) {
    return null
  }

  const RenderStep = ONBOARDING_STEPS[currentStep]

  return (
    <YStack fg={1} fs={1}>
      <RenderStep />
    </YStack>
  )
}
