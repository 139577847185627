import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M22 16V4C21.9984 3.47005 21.7872 2.96227 21.4125 2.58753C21.0377 2.2128 20.5299 2.00158 20 2H8C7.47005 2.00158 6.96227 2.2128 6.58753 2.58753C6.2128 2.96227 6.00158 3.47005 6 4V16C6.00158 16.5299 6.2128 17.0377 6.58753 17.4125C6.96227 17.7872 7.47005 17.9984 8 18H20C20.5299 17.9984 21.0377 17.7872 21.4125 17.4125C21.7872 17.0377 21.9984 16.5299 22 16ZM11 12L13.03 14.71L16 11L20 16H8L11 12ZM2 6V20C2.00158 20.5299 2.2128 21.0377 2.58753 21.4125C2.96227 21.7872 3.47005 21.9984 4 22H18V20H4V6H2Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'PhotoLibraryIcon'

export const PhotoLibraryIcon = memo<IconProps>(themed(Icon))
