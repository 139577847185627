import { YStack, Button, Spinner, Text } from '@bp/ui'
import { useState } from 'react'
import API from 'app/api'
import completion from '../images/completion.png'
import { SolitoImage } from 'solito/image'
import { useOnboardingStore } from '../store'
import { OnboardingStepDescription } from './OnboardingStepDescription'
import { useUserInfoStore } from 'app/store'
import { useAccountInfoStore } from 'app/store'
import { OnboardingStepError } from './OnboardingStepError'

export function OnboardingCompletionStep() {
  const [saving, setSaving] = useState(false)
  const steps = useOnboardingStore((s) => s.steps)
  const setStep = useOnboardingStore((s) => s.setStep)
  const fetchUserInfo = useUserInfoStore((s) => s.setUserInfo)
  const fetchAccountInfo = useAccountInfoStore((s) => s.setAccountInfo)

  const errorSteps = steps.filter((step) => !!step.error)
  const hasErrors = errorSteps?.length

  const onFinish = async () => {
    setSaving(true)
    try {
      await API.account.completeOnboarding()
      await fetchUserInfo()
      await fetchAccountInfo()
    } catch (error) {}
    setSaving(false)
  }

  const onTryAgain = () => {
    setStep(errorSteps[0].name)
  }

  return (
    <YStack fg={1} gap={30}>
      {hasErrors ? (
        <>
          <YStack gap={10}>
            <YStack>
              <Text variant="H2" centered color="$primaryDarker">
                Error
              </Text>
            </YStack>
            {!!hasErrors &&
              errorSteps.map(({ error }) => <OnboardingStepError text={error?.message} />)}
          </YStack>
          <Button variant="primary" onPress={onTryAgain} size="$5">
            Try again
          </Button>
        </>
      ) : (
        <>
          <YStack gap={10}>
            <YStack>
              <Text variant="H2" centered color="$primaryDarker">
                Up Up And
              </Text>
              <Text variant="H2" centered color="$primaryDarker">
                Away!
              </Text>
            </YStack>

            <OnboardingStepDescription text={'You have completed all steps'} />
          </YStack>
          <YStack ai="center" jc="center" fg={1}>
            <SolitoImage src={completion} alt="Bravo" width={600} height={400} />
          </YStack>

          <Button
            variant="primary"
            onPress={onFinish}
            disabled={saving}
            iconAfter={saving ? <Spinner /> : <></>}
            size="$5"
          >
            Finish
          </Button>
        </>
      )}
    </YStack>
  )
}
