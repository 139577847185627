import { createTamagui, createTokens } from 'tamagui'
import { createInterFont } from '@tamagui/font-inter'
import { shorthands } from '@tamagui/shorthands'
import { tokens } from '@tamagui/themes/v2'
import { createMedia } from '@tamagui/react-native-media-driver'

import { animations } from './animations'

const customisedTokens = createTokens({
  ...tokens,
  color: {
    ...tokens.color,
    white: '#FFFFFF',
    //Neutrals
    neutral100: '#252525',
    neutral200: '#1B2333',
    neutral300: '#606C6C',
    neutral400: '#788787',
    neutral500: '#9EA9A9',
    neutral600: '#C9CFCF',
    neutral700: '#E6EAEA',
    neutral800: '#FAFAFA',
    //Mint Green
    mintGreen100: '#148056',
    mintGreen200: '#1BAC74',
    mintGreen300: '#4AE3A8',
    mintGreen400: '#77EABE',
    mintGreen500: '#A4F1D3',
    mintGreen600: '#ECFCF6',
    //Saphire Blue
    saphireBlue100: '#003D47',
    saphireBlue200: '#006A7C',
    saphireBlue300: '#408F9D',
    saphireBlue400: '#7FB4BD',
    saphireBlue500: '#BFD9DE',
    saphireBlue600: '#E5F0F2',
    //Green
    green100: '#317A1F',
    green200: '#74D65C',
    green300: '#BAEBAD',
    green400: '#E4F7DE',
    green500: '#F1FBEF',
    //Amber
    amber100: '#FFBD3E',
    amber200: '#FFCD6E',
    amber300: '#FFDE9E',
    amber400: '#FFEECE',
    amber500: '#FFF8EB',
    //Red
    red100: '#FF6060',
    red200: '#FF8888',
    red300: '#FFAFAF',
    red400: '#FFD7D7',
    red500: '#FFEFEF',
    //Light Drizzle
    lightDrizzle100: '#BDD1D3',
    lightDrizzle200: '#CDDCDE',
    lightDrizzle300: '#DEE8E9',
    lightDrizzle400: '#EEF4F4',
    lightDrizzle500: '#F8FBFB',
    transparent: 'transparent',
  },
})

const headingFont = createInterFont({
  size: {
    1: 10,
    2: 11,
    3: 12,
    4: 13,
    5: 15,
    6: 16,
    7: 18,
    8: 20,
    9: 25,
    10: 30,
    11: 35,
    12: 45,
  },
  face: {
    700: { normal: 'InterBold' },
  },
})

const bodyFont = createInterFont(
  {
    size: {
      1: 10,
      2: 11,
      3: 12,
      4: 13,
      5: 15,
      6: 16,
      7: 18,
      8: 20,
      9: 25,
      10: 30,
      11: 35,
      12: 45,
      true: 15,
    },
    face: {
      700: { normal: 'InterBold' },
    },
  },
  {
    sizeSize: (size) => Math.round(size * 1.1),
    sizeLineHeight: (size) => Math.round(size * 1.1 + (size > 20 ? 10 : 10)),
  }
)

export const config = createTamagui({
  defaultFont: 'body',
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  onlyAllowShorthands: true,
  shorthands,
  fonts: {
    body: bodyFont,
    heading: headingFont,
  },
  settings: {
    allowedStyleValues: 'somewhat-strict',
  },
  themes: {
    light: {
      // tamagui defaults // TODO: Link
      background: customisedTokens.color.neutral800,
      backgroundFocus: customisedTokens.color.neutral500,
      backgroundHover: customisedTokens.color.neutral700,
      backgroundPress: customisedTokens.color.neutral600,
      backgroundStrong: customisedTokens.color.white,
      backgroundTransparent: customisedTokens.color.transparent,
      borderColor: customisedTokens.color.neutral700,
      borderColorFocus: customisedTokens.color.mintGreen400,
      borderColorHover: customisedTokens.color.mintGreen400,
      borderColorPress: customisedTokens.color.mintGreen300,
      color: customisedTokens.color.neutral100,
      colorFocus: customisedTokens.color.neutral300,
      colorHover: customisedTokens.color.neutral300,
      colorPress: customisedTokens.color.neutral200,
      colorTransparent: customisedTokens.color.transparent,
      placeholderColor: customisedTokens.color.neutral500,
      shadowColor: customisedTokens.color.neutral700,
      shadowColorFocus: customisedTokens.color.neutral600,
      shadowColorHover: customisedTokens.color.neutral600,
      shadowColorPress: customisedTokens.color.neutral500,
      // BP references
      primaryDarker: customisedTokens.color.mintGreen200,
      primaryDark: customisedTokens.color.mintGreen300,
      primary: customisedTokens.color.mintGreen400,
      primaryLight: customisedTokens.color.mintGreen500,
      primaryLighter: customisedTokens.color.mintGreen600,
      secondaryDarkest: customisedTokens.color.saphireBlue100,
      secondaryDarker: customisedTokens.color.saphireBlue200,
      secondaryDark: customisedTokens.color.saphireBlue300,
      secondary: customisedTokens.color.saphireBlue400,
      secondaryLight: customisedTokens.color.saphireBlue500,
      secondaryLighter: customisedTokens.color.saphireBlue600,
      darkest: customisedTokens.color.neutral100,
      darker: customisedTokens.color.neutral200,
      dark: customisedTokens.color.neutral300,
      mediumDark: customisedTokens.color.neutral400,
      medium: customisedTokens.color.neutral500,
      mediumLight: customisedTokens.color.neutral600,
      light: customisedTokens.color.neutral700,
      lighter: customisedTokens.color.neutral800,
      lightest: customisedTokens.color.white,
      errorDarker: customisedTokens.color.red100,
      errorDark: customisedTokens.color.red200,
      error: customisedTokens.color.red300,
      errorLight: customisedTokens.color.red400,
      errorLighter: customisedTokens.color.red500,
      warningDarker: customisedTokens.color.amber100,
      warningDark: customisedTokens.color.amber200,
      warning: customisedTokens.color.amber300,
      warningLight: customisedTokens.color.amber400,
      warningLighter: customisedTokens.color.amber500,
      successDarker: customisedTokens.color.green100,
      successDark: customisedTokens.color.green200,
      success: customisedTokens.color.green300,
      successLight: customisedTokens.color.green400,
      successLighter: customisedTokens.color.green500,
      infoDarker: customisedTokens.color.lightDrizzle100,
      infoDark: customisedTokens.color.lightDrizzle200,
      info: customisedTokens.color.lightDrizzle300,
      infoLight: customisedTokens.color.lightDrizzle400,
      infoLighter: customisedTokens.color.lightDrizzle500,
    },
    /** DARK theme needs refinement, until then adding light theme
    dark: {
      background: customisedTokens.color.neutral200,
      backgroundFocus: customisedTokens.color.neutral500,
      backgroundHover: customisedTokens.color.neutral300,
      backgroundPress: customisedTokens.color.neutral400,
      backgroundStrong: customisedTokens.color.neutral100,
      backgroundTransparent: customisedTokens.color.transparent,
      borderColor: customisedTokens.color.neutral300,
      borderColorFocus: customisedTokens.color.mintGreen200,
      borderColorHover: customisedTokens.color.mintGreen200,
      borderColorPress: customisedTokens.color.mintGreen300,
      color: customisedTokens.color.neutral800,
      colorFocus: customisedTokens.color.neutral700,
      colorHover: customisedTokens.color.neutral700,
      colorPress: customisedTokens.color.neutral800,
      colorTransparent: customisedTokens.color.transparent,
      placeholderColor: customisedTokens.color.neutral500,
      shadowColor: customisedTokens.color.neutral100,
      shadowColorFocus: customisedTokens.color.neutral400,
      shadowColorHover: customisedTokens.color.neutral400,
      shadowColorPress: customisedTokens.color.neutral500,
      // BP references
      primaryDarker: customisedTokens.color.saphireBlue200,
      primaryDark: customisedTokens.color.saphireBlue300,
      primary: customisedTokens.color.saphireBlue400,
      primaryLight: customisedTokens.color.saphireBlue500,
      primaryLighter: customisedTokens.color.saphireBlue600,
      secondaryDarkest: customisedTokens.color.mintGreen100,
      secondaryDarker: customisedTokens.color.mintGreen200,
      secondaryDark: customisedTokens.color.mintGreen300,
      secondary: customisedTokens.color.mintGreen400,
      secondaryLight: customisedTokens.color.mintGreen500,
      secondaryLighter: customisedTokens.color.mintGreen600,
      darkest: customisedTokens.color.white,
      darker: customisedTokens.color.neutral800,
      dark: customisedTokens.color.neutral700,
      mediumDark: customisedTokens.color.neutral600,
      medium: customisedTokens.color.neutral500,
      mediumLight: customisedTokens.color.neutral400,
      light: customisedTokens.color.neutral300,
      lighter: customisedTokens.color.neutral200,
      // Note: neutral100 for dark theme is grey. Needs to be discussed with theme refinement ticket
      // lightest: customisedTokens.color.neutral100,
      lightest: '#161c29',
      errorDarker: customisedTokens.color.red500,
      errorDark: customisedTokens.color.red400,
      error: customisedTokens.color.red300,
      errorLight: customisedTokens.color.red200,
      errorLighter: customisedTokens.color.red100,
      warningDarker: customisedTokens.color.amber500,
      warningDark: customisedTokens.color.amber400,
      warning: customisedTokens.color.amber300,
      warningLight: customisedTokens.color.amber200,
      warningLighter: customisedTokens.color.amber100,
      successDarker: customisedTokens.color.green500,
      successDark: customisedTokens.color.green400,
      success: customisedTokens.color.green300,
      successLight: customisedTokens.color.green200,
      successLighter: customisedTokens.color.green100,
      infoDarker: customisedTokens.color.lightDrizzle500,
      infoDark: customisedTokens.color.lightDrizzle400,
      info: customisedTokens.color.lightDrizzle300,
      infoLight: customisedTokens.color.lightDrizzle200,
      infoLighter: customisedTokens.color.lightDrizzle100,
    },
     */
    dark: {
      // tamagui defaults // TODO: Link
      background: customisedTokens.color.neutral800,
      backgroundFocus: customisedTokens.color.neutral500,
      backgroundHover: customisedTokens.color.neutral700,
      backgroundPress: customisedTokens.color.neutral600,
      backgroundStrong: customisedTokens.color.white,
      backgroundTransparent: customisedTokens.color.transparent,
      borderColor: customisedTokens.color.neutral700,
      borderColorFocus: customisedTokens.color.mintGreen400,
      borderColorHover: customisedTokens.color.mintGreen400,
      borderColorPress: customisedTokens.color.mintGreen300,
      color: customisedTokens.color.neutral100,
      colorFocus: customisedTokens.color.neutral300,
      colorHover: customisedTokens.color.neutral300,
      colorPress: customisedTokens.color.neutral200,
      colorTransparent: customisedTokens.color.transparent,
      placeholderColor: customisedTokens.color.neutral500,
      shadowColor: customisedTokens.color.neutral700,
      shadowColorFocus: customisedTokens.color.neutral600,
      shadowColorHover: customisedTokens.color.neutral600,
      shadowColorPress: customisedTokens.color.neutral500,
      // BP references
      primaryDarker: customisedTokens.color.mintGreen200,
      primaryDark: customisedTokens.color.mintGreen300,
      primary: customisedTokens.color.mintGreen400,
      primaryLight: customisedTokens.color.mintGreen500,
      primaryLighter: customisedTokens.color.mintGreen600,
      secondaryDarkest: customisedTokens.color.saphireBlue100,
      secondaryDarker: customisedTokens.color.saphireBlue200,
      secondaryDark: customisedTokens.color.saphireBlue300,
      secondary: customisedTokens.color.saphireBlue400,
      secondaryLight: customisedTokens.color.saphireBlue500,
      secondaryLighter: customisedTokens.color.saphireBlue600,
      darkest: customisedTokens.color.neutral100,
      darker: customisedTokens.color.neutral200,
      dark: customisedTokens.color.neutral300,
      mediumDark: customisedTokens.color.neutral400,
      medium: customisedTokens.color.neutral500,
      mediumLight: customisedTokens.color.neutral600,
      light: customisedTokens.color.neutral700,
      lighter: customisedTokens.color.neutral800,
      lightest: customisedTokens.color.white,
      errorDarker: customisedTokens.color.red100,
      errorDark: customisedTokens.color.red200,
      error: customisedTokens.color.red300,
      errorLight: customisedTokens.color.red400,
      errorLighter: customisedTokens.color.red500,
      warningDarker: customisedTokens.color.amber100,
      warningDark: customisedTokens.color.amber200,
      warning: customisedTokens.color.amber300,
      warningLight: customisedTokens.color.amber400,
      warningLighter: customisedTokens.color.amber500,
      successDarker: customisedTokens.color.green100,
      successDark: customisedTokens.color.green200,
      success: customisedTokens.color.green300,
      successLight: customisedTokens.color.green400,
      successLighter: customisedTokens.color.green500,
      infoDarker: customisedTokens.color.lightDrizzle100,
      infoDark: customisedTokens.color.lightDrizzle200,
      info: customisedTokens.color.lightDrizzle300,
      infoLight: customisedTokens.color.lightDrizzle400,
      infoLighter: customisedTokens.color.lightDrizzle500,
    },
  },
  tokens: customisedTokens,
  media: createMedia({
    xxs: { maxWidth: 425 },
    xs: { maxWidth: 660 },
    sm: { maxWidth: 800 },
    md: { maxWidth: 1020 },
    lg: { maxWidth: 1280 },
    xl: { maxWidth: 1420 },
    xxl: { maxWidth: 1600 },
    gtXxs: { maxWidth: 425 + 1 },
    gtXs: { minWidth: 660 + 1 },
    gtSm: { minWidth: 800 + 1 },
    gtMd: { minWidth: 1020 + 1 },
    gtLg: { minWidth: 1280 + 1 },
    gtXl: { minWidth: 1420 + 1 },
    short: { maxHeight: 820 },
    tall: { minHeight: 820 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  }),
})

// for the compiler to find it
export default config
