import React from 'react'
import { Button, Image, XStack, YStack, Stack, Text, CameraIcon, PhotoLibraryIcon } from '@bp/ui'
import { useState } from 'react'
import * as ImagePicker from 'expo-image-picker'
import { useUserInfoStore } from 'app/store'
import { Platform } from 'react-native'
import { OnboardingStep } from './OnboardingStep'
import { useOnboardingStore } from '../store'

export function OnboardingProfileImageStep() {
  const avatar = useUserInfoStore((store) => store.avatar)
  const [selectedImage, setSelectedImage] = useState(avatar.lg || '')
  const [error, setError] = useState()
  const continueEnabled = !!selectedImage
  const currentIndex = useOnboardingStore((s) => s.currentIndex)
  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const saveProfileImage = useOnboardingStore((s) => s.saveProfileImage)
  const isDevice = Platform.OS !== 'web'

  const w = 200

  const onContinue = async () => {
    // if the profile pic has not changed, skip the setter
    if (avatar.lg === selectedImage) {
      showNextStep()
      return
    }

    saveProfileImage({ value: selectedImage, index: currentIndex })
    showNextStep()
  }

  const onUploadImage = async () => {
    setError(false)
    try {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        setError({ message: 'BP: Permission not granted' })
        return
      }

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
        base64: true,
      })

      if (!result.canceled) {
        setSelectedImage(`data:image/png;base64,${result.assets[0].base64}`)
      }
    } catch (e) {
      setError('BP: Error saving photo')
    }
  }

  const onCaptureImage = async () => {
    setError('')
    try {
      const { status } = await ImagePicker.requestCameraPermissionsAsync()
      if (status !== 'granted') {
        setError('BP: Permission not granted')
        return
      }

      const result = await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        quality: 1,
        base64: true,
      })

      if (!result.canceled) {
        setSelectedImage(`data:image/png;base64,${result.assets[0].base64}`)
      }
    } catch (e) {}
  }

  return (
    <OnboardingStep title={'Add a profile picture'}>
      <YStack fg={1} gap={25}>
        <XStack jc="center">
          <Stack w={w} h={w} bw={6} borderRadius={100} boc="$primary">
            <Image
              source={{
                uri: selectedImage,
                width: w - 12,
                height: w - 12,
              }}
              br={100}
            />
          </Stack>
        </XStack>
        <XStack gap={10}>
          {isDevice && (
            <Button variant="outlined" icon={<CameraIcon />} fg={1} onPress={onCaptureImage}>
              Camera
            </Button>
          )}
          <Button variant="outlined" icon={<PhotoLibraryIcon />} fg={1} onPress={onUploadImage}>
            Upload
          </Button>
        </XStack>
        <YStack>
          <Button variant="primary" onPress={onContinue} disabled={!continueEnabled}>
            Continue
          </Button>
        </YStack>
      </YStack>
    </OnboardingStep>
  )
}
