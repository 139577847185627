import {
  Button,
  Avatar as BPAvatar,
  Popover,
  PopoverProps,
  Adapt,
  XStack,
  YStack,
  Stack,
  Text,
} from '@bp/ui'
import { useLink } from 'solito/link'
import { useUserInfoStore } from 'app/store'
import { useAccountInfoStore } from 'app/store'
import { useOnboardingStore } from 'app/features'
import { useAuth } from 'app/lib/auth'
import { useState } from 'react'
import { useRouter } from 'solito/router'

/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */
export default function Avatar() {
  const avatar = useUserInfoStore((state) => state.avatar)
  const startOnboardingTestUser = useUserInfoStore((state) => state.startOnboardingTest)
  const startOnboardingTestAccount = useAccountInfoStore((state) => state.startOnboardingTest)
  const startOnboardingTestOB = useOnboardingStore((state) => state.startOnboardingTest)
  const { user, logout } = useAuth()
  const [open, setOpen] = useState(false)
  const { push } = useRouter()

  const profileProps = {
    onPress: () => {
      setOpen(false)
      push(`/user/${user?.nickname}`)
    },
  }

  const settingsProps = {
    onPress: () => {
      setOpen(false)
      push('/settings')
    },
  }
  const logoutProps = {
    onPress: () => {
      setOpen(false)
      logout()
    },
  }
  const designProps = {
    onPress: () => {
      setOpen(false)
      push('/design')
    },
  }
  const onboardingProps = {
    onPress: () => {
      startOnboardingTestUser()
      startOnboardingTestAccount()
      startOnboardingTestOB()
    },
  }

  return (
    <CustomPopover
      placement="right-end"
      open={open}
      setOpen={setOpen}
      Icon={
        avatar?.md
          ? () => (
              <Stack>
                <BPAvatar circular size={36} src={avatar?.md} />
              </Stack>
            )
          : () => null
      }
      Content={() => (
        <YStack>
          <Button variant="action" width={180} {...profileProps} px={10}>
            My profile
          </Button>
          <Button variant="action" width={180} {...settingsProps} px={10}>
            Settings
          </Button>
          {/* 
          <Button variant="action" width={180} {...onboardingProps} px={10}>
            Restart Onboarding
          </Button>
          <Button variant="action" width={180} {...designProps} px={10}>
            Design
          </Button> */}
          <Button variant="action" width={180} {...logoutProps} px={10}>
            Logout
          </Button>
        </YStack>
      )}
    />
  )
}

function CustomPopover({
  Icon,
  Name,
  Content,
  open,
  setOpen,
  ...props
}: PopoverProps & { Icon?: any; Name?: string; Content?: any; setOpen?: any; open: boolean }) {
  return (
    <Popover
      size="$4"
      allowFlip
      {...props}
      open={open}
      onOpenChange={setOpen}
      placement="left-start"
      shadowColor="$dark"
    >
      <Popover.Trigger asChild>
        <Button variant="link">
          <Icon />
        </Button>
      </Popover.Trigger>

      <Popover.Content
        borderWidth={1}
        borderColor="$borderColor"
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        elevate={1}
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
      >
        <Popover.Arrow borderWidth={1} borderColor="$borderColor" />
        <YStack gap="$3">
          <XStack gap="$3">
            <Content />
          </XStack>
        </YStack>
      </Popover.Content>
    </Popover>
  )
}
