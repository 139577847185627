import { OnboardingPermissionsStep } from './OnboardingPermissionsStep'
import PermissionImage from '../images/notification.png'

export function OnboardingPushNotificationPermissionsStep() {
  return (
    <OnboardingPermissionsStep
      title={'Allow us to send you important updates and alerts'}
      permission="Notifications"
      image={PermissionImage}
    />
  )
}
