import { useEffect } from 'react'
import { YStack, Text } from '@bp/ui'
import { useUserInfoStore } from 'app/store'
import { useStream } from 'app/lib/stream/useStream'
import { useStreamPushNotification } from 'app/lib/stream//useStreamPushNotification'
import { useAuth } from 'app/lib/auth'

export function StreamCheckPoint({ children }) {
  const { user, accessToken } = useAuth()
  const connect = useStream((s) => s.connect)
  const disconnect = useStream((s) => s.disconnect)
  const chatConnected = useStream((s) => s.chatConnected)
  const chatConnecting = useStream((s) => s.chatConnecting)
  const videoConnected = useStream((s) => s.chatConnected)
  const videoConnecting = useStream((s) => s.videoConnecting)
  const disconnected = useStream((s) => s.disconnected)
  // Connect to GS chat and video/audio client
  const connecting = chatConnecting || videoConnecting
  const connected = chatConnected && videoConnected
  const ready = !connected && !connecting && !disconnected && user && accessToken

  // Notification permission and device registration
  useStreamPushNotification()

  useEffect(() => {
    if (!ready) {
      return
    }

    connect()

    return () => {
      if (!connected && !connecting) {
        disconnect()
      }
    }
  }, [ready])

  return <>{children}</>
}
