import React from 'react'
import { useUserInfoStore } from 'app/store'
import { ACCOUNT_STATUS } from 'app/utils'
import { OnboardingScreen } from '../screens'
import { useAuth } from 'app/lib/auth'

export function OnboardingCheckPoint({ children }) {
  const accountStatus = useUserInfoStore((store) => store.account_status)
  const { user, isLoading } = useAuth()
  // render screen for non-authenticated
  const guest = !user && !isLoading

  // guest user
  if (guest) {
    return <>{children}</>
  }

  // Note: Return the component early to test onboarding
  // return <OnboardingScreen />

  // account is active, render app
  const isActiveAccount = accountStatus === ACCOUNT_STATUS.active
  if (isActiveAccount) {
    return <>{children}</>
  }

  // if onboarding is incomplete, show registration screens
  const isAccountStatusPending = accountStatus === ACCOUNT_STATUS.pending
  if (isAccountStatusPending) {
    return <OnboardingScreen />
  }

  // nothing to show for now
  return null
}
