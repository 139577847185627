import '@tamagui/core/reset.css'
import '@tamagui/font-inter/css/400.css'
import '@tamagui/font-inter/css/700.css'
import 'raf/polyfill'
import '../public/fonts/OpenSans.css'
import 'stream-chat-react/dist/css/v2/index.css'
import React, { useEffect } from 'react'
import type { SolitoAppProps } from 'solito'
import Layout from '../components/layout'

// https://firebase.google.com/docs/web/modular-upgrade
import { initializeApp } from 'app/lib/firebase/app'
import { getAnalytics, isSupported } from 'app/lib/firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.BP_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.BP_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.BP_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.BP_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.BP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.BP_PUBLIC_FIREBASE_MESSAGING_APP_ID,
  measurementId: process.env.BP_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

if (process.env.NODE_ENV === 'production') {
  require('../public/tamagui.css')
}

function MyApp({ Component, pageProps }: SolitoAppProps) {
  const firebaseApp = initializeApp(firebaseConfig)

  useEffect(() => {
    if (isSupported()) {
      const firebaseAnalytics = getAnalytics(firebaseApp)
      console.log('firebaseAnalytics', firebaseAnalytics)
    }
  }, [])

  return (
    <Layout {...pageProps}>
      <Component {...pageProps} />
    </Layout>
  )
}

export default MyApp
