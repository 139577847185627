import { YStack, Text } from '@bp/ui'
export function OnboardingStepTitle({ text }) {
  return (
    <YStack fg={1} fs={1}>
      <Text variant="H4" centered>
        {text || '<OnboardingStepTitle>'}
      </Text>
    </YStack>
  )
}
