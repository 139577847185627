import { YStack, Text, Button, Logo, ExclamationCircleIcon, XStack } from '@bp/ui/src'
import { useState } from 'react'
import { useAuth } from 'app/lib/auth'
import { useAccountInfoStore } from 'app/store'
import { useUserInfoStore } from 'app/store'
export function UserAccountError({ text, activate }) {
  const { logout } = useAuth()
  const activateAccount = useAccountInfoStore((state) => state.activateAccount)
  const fetchAccountInfo = useAccountInfoStore((state) => state.setAccountInfo)
  const fetchUserInfo = useUserInfoStore((state) => state.setUserInfo)

  const onActivateAccount = async () => {
    await activateAccount()
    fetchUserInfo()
    fetchAccountInfo()
  }

  return (
    <YStack ai="center" py={80} gap={25} jc="center">
      <YStack>
        <ExclamationCircleIcon size="$8" color="$mediumLight" />
      </YStack>
      <Text variant="callout" color="$mediumDark">
        {text}
      </Text>
      <XStack gap={10}>
        <Button variant="primary" onPress={logout}>
          Logout
        </Button>
        {activate && (
          <Button variant="outlined" onPress={onActivateAccount}>
            Activate
          </Button>
        )}
      </XStack>
    </YStack>
  )
}
