import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M12 8C12.3956 8 12.7822 7.8827 13.1111 7.66294C13.44 7.44318 13.6964 7.13082 13.8478 6.76537C13.9991 6.39992 14.0387 5.99778 13.9616 5.60982C13.8844 5.22186 13.6939 4.86549 13.4142 4.58579C13.1345 4.30608 12.7781 4.1156 12.3902 4.03843C12.0022 3.96126 11.6001 4.00087 11.2346 4.15224C10.8692 4.30362 10.5568 4.55996 10.3371 4.88886C10.1173 5.21776 10 5.60444 10 6C10.0016 6.52995 10.2128 7.03774 10.5875 7.41247C10.9623 7.7872 11.4701 7.99842 12 8ZM12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C13.9984 11.4701 13.7872 10.9623 13.4125 10.5875C13.0377 10.2128 12.5299 10.0016 12 10ZM12 16C11.6044 16 11.2178 16.1173 10.8889 16.3371C10.56 16.5568 10.3036 16.8692 10.1522 17.2346C10.0009 17.6001 9.96126 18.0022 10.0384 18.3902C10.1156 18.7781 10.3061 19.1345 10.5858 19.4142C10.8655 19.6939 11.2219 19.8844 11.6098 19.9616C11.9978 20.0387 12.3999 19.9991 12.7654 19.8478C13.1308 19.6964 13.4432 19.44 13.6629 19.1111C13.8827 18.7822 14 18.3956 14 18C13.9984 17.4701 13.7872 16.9623 13.4125 16.5875C13.0377 16.2128 12.5299 16.0016 12 16Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'MoreVerticalIcon'

export const MoreVerticalIcon = memo<IconProps>(themed(Icon))
