import { Button, YStack } from '@bp/ui'
import React, { useState, useMemo } from 'react'
import { useAccountInfoStore } from 'app/store'
import { useOnboardingStore } from '../store'
import { ONBOARDING_DOB_TEXT1, ONBOARDING_DOB_TEXT2, ONBOARDING_DOB_BUTTON_TEXT1 } from '../helpers'
import { format, differenceInYears } from 'date-fns'
import { OnboardingStep } from './OnboardingStep'
import { UserBirthday } from 'app/features/user'

export function OnboardingDateOfBirthStep() {
  const [error, setError] = useState(true)
  const dob_y_m_d = useAccountInfoStore((store) => store.dob_y_m_d)
  const currentIndex = useOnboardingStore((s) => s.currentIndex)
  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const saveDateOfBirth = useOnboardingStore((s) => s.saveDateOfBirth)
  const minAge = 13
  const [date, setDate] = useState(dob_y_m_d && new Date(dob_y_m_d))

  const isValidAge = useMemo(() => {
    if (date) {
      const currentDate = new Date()
      const age = differenceInYears(currentDate, date)

      if (age < minAge) {
        return false
      }

      return true
    } else {
      return false
    }
  }, [date])

  const handleSave = async () => {
    const value = format(date, 'yyyy-MM-dd')
    saveDateOfBirth({ value, index: currentIndex })
    showNextStep()
  }

  return (
    <OnboardingStep
      title={ONBOARDING_DOB_TEXT1}
      description1={ONBOARDING_DOB_TEXT2}
      next={false}
      previous={false}
    >
      <YStack>
        <UserBirthday value={date} onError={setError} onChange={setDate} />
      </YStack>
      <Button variant="primary" onPress={handleSave} disabled={!isValidAge}>
        {ONBOARDING_DOB_BUTTON_TEXT1}
      </Button>
    </OnboardingStep>
  )
}
