import { YStack, Theme, ScrollView } from '@bp/ui'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import Protected from 'app/features/layout/protected'
import { Onboarding } from '../components'
import { useMedia } from '@bp/ui'

export function OnboardingScreen() {
  const media = useMedia()

  const styles = {
    safeAreaView: {
      flex: 1,
    },
    container: {
      backgroundColor: media?.gtSm ? '$darker' : '$lightest',
      justifyContent: media?.gtSm ? 'center' : 'start',
      alignItems: media?.gtSm ? 'center' : 'auto',
      paddingVertical: media?.gtSm ? 20 : 0,
    },
    content: {
      flex: media?.gtSm ? 0 : 1,
      width: media?.gtSm ? 500 : 'auto',
      //minHeight: media?.gtSm ? 500 : 'auto',
      borderRadius: 10,
      justifyContent: media?.gtSm ? 'center' : 'start',
    },
  }

  return (
    <Theme>
      <Protected>
        <SafeAreaView style={{ ...styles.safeAreaView }}>
          <YStack fg={1} {...styles.container}>
            <YStack {...styles.content} fg={1} jc="center">
              <ScrollView jc={media?.gtSm ? 'center' : 'start'}>
                <Onboarding />
              </ScrollView>
            </YStack>
          </YStack>
        </SafeAreaView>
      </Protected>
    </Theme>
  )
}
