import { decode } from 'base-64'
global.atob = decode
import { YStack, Spinner } from '@bp/ui'
import { useQuery } from '@tanstack/react-query'
import API from 'app/api'
import { useUserInfoStore } from 'app/store'
import { BellPopover } from './popover'

export default function Bell() {
  const userId = useUserInfoStore((state) => state.id)
  const { data: gsAccessTokenData, isPending } = useQuery({
    queryFn: API.user.getStreamIoToken,
    queryKey: ['gsAccessToken'],
  })

  if (isPending) {
    return (
      <YStack justifyContent="center">
        <Spinner />
      </YStack>
    )
  }

  return <BellPopover accessToken={gsAccessTokenData?.access_token} userId={userId} />
}
