import { Button as TuiButton, styled } from 'tamagui'

export const Button = styled(TuiButton, {
  name: 'Button',
  fontWeight: '500',
  backgroundColor: '$backgroundTransparent',
  hoverStyle: {
    backgroundColor: '$backgroundTransparent',
    borderColor: '$backgroundTransparent',
  },
  pressStyle: {
    backgroundColor: '$backgroundTransparent',
    borderColor: '$backgroundTransparent',
  },
  focusStyle: {
    outlineColor: '$secondaryLight',
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: '$secondaryDarker',
        color: '$lighter',
        hoverStyle: {
          backgroundColor: '$darker',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$darker',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          borderColor: '$lightest',
          outlineColor: '$secondaryLight',
        },
      },
      primaryInverse: {
        backgroundColor: '$secondaryLighter',
        color: '$secondaryDarker',
        hoverStyle: {
          backgroundColor: '$$secondaryLight',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$secondaryLight',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          borderColor: '$backgroundTransparent',
          outlineColor: '$secondaryLight',
        },
      },
      accent: {
        backgroundColor: '$primaryDark',
        color: '$darker',
        hoverStyle: {
          backgroundColor: '$primaryDarker',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$primaryDarker',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          borderColor: '$lightest',
          outlineColor: '$primaryDark',
        },
      },
      secondary: {
        backgroundColor: '$light',
        color: '$darker',
        hoverStyle: {
          backgroundColor: '$dark',
          color: '$light',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$dark',
          color: '$light',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          outlineColor: '$light',
          borderColor: '$lightest',
        },
      },
      tertiary: {
        backgroundColor: '$backgroundTransparent',
        paddingLeft: 0,
        paddingRight: 0,
        color: '$darkest',
        borderBottomColor: '$primaryDark',
        borderRadius: 0,
        borderBottomWidth: 3,
        borderWidth: 0,
        hoverStyle: {
          color: '$primaryDarker',
          borderBottomColor: '$primaryDarker',
          backgroundColor: '$backgroundTransparent',
        },
        pressStyle: {
          color: '$primaryDarker',
          borderBottomColor: '$primaryDarker',
          backgroundColor: '$backgroundTransparent',
        },
        focusStyle: {
          color: '$darkest',
          outlineColor: '$primaryLight',
        },
      },
      outlined: {
        borderColor: '$secondaryDark',
        backgroundColor: '$lightest',
        color: '$secondaryDarker',
        borderWidth: 1,
        hoverStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
          color: '$secondaryDark',
        },
        pressStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
        },
        focusStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
        },
      },
      chatMic: {
        borderColor: '$errorLight',
        backgroundColor: '$lightest',
        color: '#DE353E',
        borderWidth: 3,
        borderRadius: 5,
        hoverStyle: {
          borderColor: '$errorLight',
          backgroundColor: '$lightest',
          color: '#DE353E',
        },
        pressStyle: {
          borderColor: '$errorLight',
          backgroundColor: '$lightest',
        },
        focusStyle: {
          borderColor: '$errorLight',
          backgroundColor: '$lightest',
        },
      },
      chatMicReq: {
        borderColor: '$secondaryDark',
        backgroundColor: '$lightest',
        color: '$secondaryDark',
        borderWidth: 2,
        borderRadius: 5,
        hoverStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
          color: '$secondaryDark',
        },
        pressStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
        },
        focusStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
        },
      },
      link: {
        backgroundColor: '$backgroundTransparent',
        color: '$secondaryDark',
        paddingLeft: 0,
        paddingRight: 0,
        fontWeight: '600',
      },
      text: {
        backgroundColor: '$backgroundTransparent',
        color: '$color',
        padding: 0,
        height: 'auto',
      },
      compact: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      danger: {
        backgroundColor: '$errorDarker',
        color: '$lightest',
        hoverStyle: {
          backgroundColor: '$errorDark',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$errorDark',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          borderColor: '$errorLighter',
          outlineColor: '$errorDarker',
        },
      },
      dangerOutlined: {
        borderColor: '#DE353E',
        backgroundColor: '$lighter',
        color: '#DE353E',
        borderWidth: 1,
        hoverStyle: {
          borderColor: '#DE353E',
          backgroundColor: '$lightest',
          color: '#DE353E',
        },
        pressStyle: {
          borderColor: '#DE353E',
          backgroundColor: '$lightest',
        },
        focusStyle: {
          borderColor: '#DE353E',
          backgroundColor: '$lightest',
        },
      },
      message: {
        display: 'flex',
        padding: 10,
        height: 'auto',
        color: '$lightest',
        margin: 0,
      },
      reactions: {
        display: 'flex',
        padding: 0,
        height: 'auto',
        backgroundColor: 'transparent',
      },
      action: {
        padding: 0,
        paddingHorizontal: 4,
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        width: 240,
        hoverStyle: {
          backgroundColor: '$light',
        },
        pressStyle: {
          backgroundColor: '$light',
        },
      },
      date: {
        borderWidth: 1,
        borderColor: '$light',
        backgroundColor: '$lighter',
        color: '$dark',
        fontSize: 18,
        focusStyle: {
          outlineWidth: '$1.5',
          outlineColor: '$primaryLighter',
        },
        pressStyle: {
          outlineWidth: '$1.5',
          outlineColor: '$primaryLighter',
        },
      },
      hiddenOverlay: {
        backgroundColor: '$lightest',
        opacity: 0.05,
        focusStyle: {
          backgroundColor: '$lightest',
        },
        pressStyle: {
          backgroundColor: '$lightest',
        },
      },
    },
    selected: {
      true: {
        backgroundColor: '$secondaryDarker',
      },
    },

    disabled: {
      true: {
        backgroundColor: '$secondary',
        color: '$lighter',
        hoverStyle: {
          backgroundColor: '$lighter',
          color: '$medium',
        },
        focusStyle: {
          backgroundColor: '$lighter',
          color: '$medium',
        },
        pressStyle: {
          backgroundColor: '$lighter',
          color: '$medium',
        },
      },
    },
  },
  defaultVariants: {
    size: '$4',
  },
})
