import { YStack, XStack, Stack, Text, Button } from '@bp/ui'
import { useOnboardingStore } from '../store'
import { OnboardingStepTitle } from './OnboardingStepTitle'
import { OnboardingStepDescription } from './OnboardingStepDescription'
import { OnboardingStepError } from './OnboardingStepError'
import { OnboardingLogo } from './OnboardingLogo'
export function OnboardingStep({ title, description1, description2, next = true, previous = true, children }) {
  const currentIndex = useOnboardingStore((s) => s.currentIndex)
  const steps = useOnboardingStore((s) => s.steps)
  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const showPreviousStep = useOnboardingStore((s) => s.showPreviousStep)

  const step = steps[currentIndex]
  const { error } = step

  return (
    <Stack
      fs={1}
      fg={1}
      animateOnly={['transform', 'opacity']}
      animation={[
        'quick',
        {
          opacity: {
            overshootClamping: true,
          },
        },
      ]}
      enterStyle={{ opacity: 0, scale: 0.9 }}
      exitStyle={{ opacity: 0, scale: 1 }}
    >
      <YStack mb={30}>
        <OnboardingLogo />
      </YStack>
      <YStack gap={25} fs={1} mb={10}>
        {!!title && <OnboardingStepTitle text={title} />}
        {!!description1 && <OnboardingStepDescription text={description1} />}
        {!!description2 && <OnboardingStepDescription text={description2} />}
        {error && <OnboardingStepError text={error?.message} />}
      </YStack>
      <YStack fg={1} gap={30} pb={10}>
        {children}
      </YStack>
      <XStack gap={10}>
        {(currentIndex > 0 && previous) && (
          <Stack fg={1}>
            <Button onPress={showPreviousStep} variant="secondary">
              Back
            </Button>
          </Stack>
        )}
        {(currentIndex < steps.length - 1 && next) && (
          <Stack fg={1}>
            <Button onPress={showNextStep} variant="outlined">
              Skip
            </Button>
          </Stack>
        )}
      </XStack>
    </Stack>
  )
}
