import { Button, Spinner, XStack, YStack, Text } from '@bp/ui'
import { useEffect, useState } from 'react'
import API from 'app/api'
import { useOnboardingStore } from '../store'
import { OnboardingStep } from './OnboardingStep'

const INTERESTS_ICONS = {
  Basketball: '🏀',
  Football: '🏈',
  Gaming: '🎮',
  Dating: '💋',
  Soccer: '⚽',
  Movies: '🍿',
  Events: '🎟️',
  Nightlife: '🥂',
  Sneakers: '👟',
  Art: '🎨',
  Finance: '💵',
  Music: '🎵',
  Boxing: '🥊',
  News: '📰',
}

export function OnboardingChooseInterestsStep() {
  const [loading, setLoading] = useState(false)
  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const saveInterests = useOnboardingStore((s) => s.saveInterests)
  const currentIndex = useOnboardingStore((s) => s.currentIndex)
  const interests = useOnboardingStore((s) => s.interests)
  const setInterests = useOnboardingStore((s) => s.setInterests)
  const interested = useOnboardingStore((s) => s.interested)
  const setInterested = useOnboardingStore((s) => s.setInterested)

  const fetchInterests = async () => {
    setLoading(true)
    const interests = await API.util.getInterests()
    setInterests([...interests])
    setLoading(false)
  }

  useEffect(() => {
    if (!interests?.length) {
      fetchInterests()
    }
  }, [interests])

  const handleInterest = (interest) => (e) => {
    try {
      const alreadyInterested = interested.includes(interest.id)
      const mergedInterested = alreadyInterested
        ? interested.filter((i) => i !== interest.id)
        : [...interested, interest.id]
      console.log('mergedInterested', mergedInterested)
      console.log('setInterested', setInterested)
      setInterested(mergedInterested)
    } catch (e) {}
  }

  const handleContinue = () => {
    saveInterests({ value: interested, currentIndex })
    showNextStep()
  }

  return (
    <OnboardingStep
      title={'Choose Your Interests'}
      description1={'Select at least 3 topics that interest you.'}
    >
      {interests?.length > 0 ? (
        <XStack gap="$3" jc="start" fw="wrap" ai="center" mb="$3">
          {interests.map((interest) => {
            const alreadyInterested = interested.includes(interest.id)
            return (
              <Button
                key={interest.id}
                onPress={handleInterest(interest)}
                variant={!alreadyInterested ? 'secondary' : 'primary'}
                borderRadius="$8"
                width="47%"
              >
                <Text fontSize={22}>{INTERESTS_ICONS[interest.value]}</Text>
                {interest.value}
              </Button>
            )
          })}
        </XStack>
      ) : loading ? (
        <Spinner my={15} />
      ) : (
        <Text textAlign="center">No interests list.</Text>
      )}

      <Button variant="primary" onPress={handleContinue} disabled={interested.length < 3}>
        Continue
      </Button>
    </OnboardingStep>
  )
}
