const MAX_LIMIT = 25

// Note: 25 is the max limit. We need to include pagination behaviour with design

export const queryPublicRooms = async (client, limit = MAX_LIMIT) => {
  if (!client) {
    return []
  }

  const { calls } = await client.queryCalls({
    filter_conditions: {
      type: { $eq: process.env.BP_PUBLIC_GS_PUBLIC_ROOM },
      $and: [{ ongoing: true, ended_at: { $exists: false } }],
    },
    limit,
  })

  return calls
}

export const queryPrivateRooms = async (client, limit = MAX_LIMIT) => {
  if (!client) {
    return []
  }

  const userID = client?.streamClient?.userID

  const { calls } = await client.queryCalls({
    filter_conditions: {
      type: { $eq: process.env.BP_PUBLIC_GS_PRIVATE_ROOM },
      $or: [{ created_by_user_id: userID }, { members: { $in: [userID] } }],
      $and: [{ ended_at: { $exists: false } }],
    },
    limit,
  })

  return calls
}

export const queryPublicExpiredRooms = async (client, limit = MAX_LIMIT) => {
  return await queryExpiredRooms(client, process.env.BP_PUBLIC_GS_PUBLIC_ROOM, limit)
}

export const queryPrivateExpiredRooms = async (client, limit = MAX_LIMIT) => {
  return await queryExpiredRooms(client, process.env.BP_PUBLIC_GS_PRIVATE_ROOM, limit)
}

export const queryExpiredRooms = async (client, type, limit = MAX_LIMIT) => {
  if (!client) {
    return []
  }

  const userID = client?.streamClient?.userID

  const { calls } = await client.queryCalls({
    filter_conditions: {
      type: { $eq: type },
      $or: [{ created_by_user_id: userID }, { members: { $in: [userID] } }],
      $and: [{ ended_at: { $exists: true } }],
    },
    limit,
  })

  return calls
}
