import React from 'react'
import { XStack } from '@bp/ui'
import { OnboardingStepIndicator } from './OnboardingStepIndicator'

export function OnboardingStepsIndicator({ steps, currentStep }) {
  const width = 260 / steps?.length
  return (
    <XStack jc="center" fg={1} gap={8}>
      {steps.map((step) =>
        step.hideNav ? null : (
          <OnboardingStepIndicator
            {...step}
            active={step.name === currentStep}
            error={step.error}
            key={step.name}
            width={width}
          />
        )
      )}
    </XStack>
  )
}
