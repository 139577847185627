import { useEffect } from 'react'
import API from 'app/api'

export const usePostLogin = ({ accessToken }) => {
  // Call api after successful authentication
  useEffect(() => {
    if (!accessToken) {
      return
    }

    API.auth.setLastLogin()
  }, [accessToken])

  return {}
}
