import { useState } from 'react'
import { Popover } from 'tamagui'
import { decode } from 'base-64'
global.atob = decode
import { Text, YStack, Spinner } from '@bp/ui'
import React, { useEffect } from 'react'
import { NotificationsIcon, Button } from '@bp/ui'
import { Circle } from 'tamagui'
import { connect } from 'getstream'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Notification from './notification'
import { CloseCrossIcon } from '@bp/ui/src/Iconsx'

const API_KEY = process.env.BP_PUBLIC_GS_KEY
const APP_ID = process.env.BP_PUBLIC_GS_ID

export function BellPopover({ accessToken, userId, ...props }) {
  const queryClient = useQueryClient()
  const client = connect(API_KEY, accessToken, APP_ID)
  const notificationFeed = client.feed('notification', userId)

  useEffect(() => {
    const callback = (data) => {
      queryClient.invalidateQueries({ queryKey: ['notificationsData'] })
    }
    const successCallback = () => {}
    const failCallback = (data) => {
      console.error('subscribe error', data)
    }
    const subscription = notificationFeed.subscribe(callback).then(successCallback, failCallback)
    return () => subscription?.cancel && subscription.cancel()
  }, [])

  const { data: notificationsData, isPending: notificationsIsPending } = useQuery({
    queryKey: ['notificationsData'],
    queryFn: () => notificationFeed.get({ limit: 20 }),
  })

  const handleOpenNotifications = () => {
    setIsOpen((v) => !v)
    notificationFeed.get({ limit: 20, mark_seen: true })
  }

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover open={isOpen} onOpenChange={(v) => setIsOpen(v)} size="$5" allowFlip {...props}>
      <Popover.Trigger asChild>
        <YStack justifyContent="center">
          <Button
            variant="text"
            onPress={handleOpenNotifications}
            icon={<NotificationsIcon size={24} />}
          />
          <YStack style={{ position: 'absolute', pointerEvents: 'none' }}>
            {notificationsIsPending ? (
              <Spinner size={16} />
            ) : (
              <>
                {notificationsData.unseen > 0 && (
                  <Circle size={16} backgroundColor="$errorDarker" pointerEvents="none">
                    <Text fontSize="$1" color="$lightest">
                      {notificationsData.unseen}
                    </Text>
                  </Circle>
                )}
              </>
            )}
          </YStack>
        </YStack>
      </Popover.Trigger>

      <Popover.Content
        padding={0}
        paddingBottom="$2"
        borderWidth={1}
        borderColor="$borderColor"
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        elevate
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
      >
        <Popover.Arrow borderWidth={1} borderColor="$borderColor" />

        <Popover.Close asChild>
          <YStack alignSelf="flex-end">
            <Button
              onPress={() => {
                setIsOpen(false)
              }}
            >
              <CloseCrossIcon />
            </Button>
          </YStack>
        </Popover.Close>

        <YStack>
          <YStack>
            {notificationsData?.results?.map((notification) => (
              <Notification
                notification={notification}
                key={notification.id}
                setIsOpen={setIsOpen}
                notificationFeed={notificationFeed}
              />
            ))}
            {notificationsData?.results?.length === 0 && (
              <Text padding="$4">You are up to date!</Text>
            )}
          </YStack>
        </YStack>
      </Popover.Content>
    </Popover>
  )
}
