export const ONBOARDING_CHOOSE_INTEREST_STEP = 'ONBOARDING_CHOOSE_INTEREST_STEP'
export const ONBOARDING_COMPLETION_STEP = 'ONBOARDING_COMPLETION_STEP'
export const ONBOARDING_DATE_OF_BIRTH_STEP = 'ONBOARDING_DATE_OF_BIRTH_STEP'
export const ONBOARDING_DISPLAY_NAME_STEP = 'ONBOARDING_DISPLAY_NAME_STEP'
export const ONBOARDING_DISPLAY_LOCATION_STEP = 'ONBOARDING_DISPLAY_LOCATION_STEP'
export const ONBOARDING_FOLLOW_RECOMMENDATIONS_STEP = 'ONBOARDING_FOLLOW_RECOMMENDATIONS_STEP'
export const ONBOARDING_IMAGE_PICKER_PERMISSIONS_STEP = 'ONBOARDING_IMAGE_PICKER_PERMISSIONS_STEP'
export const ONBOARDING_LOCATION_PERMISSIONS_STEP = 'ONBOARDING_LOCATION_PERMISSIONS_STEP'
export const ONBOARDING_PROFILE_IMAGE_STEP = 'ONBOARDING_PROFILE_IMAGE_STEP'
export const ONBOARDING_PUSH_NOTIFICATION_PERMISSIONS_STEP =
  'ONBOARDING_PUSH_NOTIFICATION_PERMISSIONS_STEP'
export const ONBOARDING_PENDING_EMAIL_VERIFICATION_STEP =
  'ONBOARDING_PENDING_EMAIL_VERIFICATION_STEP'
export const ONBOARDING_USERNAME_STEP = 'ONBOARDING_USERNAME_STEP'
export const ONBOARDING_CHOOSE_GENDER_STEP = 'ONBOARDING_CHOOSE_GENDER_STEP'
export const ONBOARDING_CONTACTS_PERMISSIONS_STEP = 'ONBOARDING_CONTACTS_PERMISSIONS_STEP'
