//TODO: Revisit implementation

import { useState } from 'react'
import { Stack } from '..'
import { Text } from '../Text'
import { DatePicker } from '../DatePicker'
import { differenceInYears } from 'date-fns'

const Birthday = ({ onChange = (d) => d, onError = (e) => e, value, ...contentProps }) => {
  const [error, setError] = useState(false)
  const minAge = 13

  const handleDateChange = (date) => {
    onError(false)
    setError(false)

    const birthDate = new Date(date)
    const currentDate = new Date()
    const age = differenceInYears(currentDate, birthDate)

    onChange(birthDate)

    if (age < minAge) {
      setError(true)
      onError(true)
    }
  }

  return (
    <Stack {...contentProps}>
      <DatePicker value={value} onChange={handleDateChange} onError={setError} />
      {error && (
        <Text pt={10} variant="caption" error centered>
          You must be at least 13 years old.
        </Text>
      )}
    </Stack>
  )
}

export { Birthday }
