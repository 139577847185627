import { Button, Select, Spinner } from '@bp/ui'
import { useState } from 'react'
import { useAccountInfoStore } from 'app/store'
import React from 'react'
import { useOnboardingStore } from '../store'
import { OnboardingStep } from './OnboardingStep'

export function OnboardingChooseGenderStep() {
  const gender = useAccountInfoStore((store) => store.gender)
  const available_genders = useAccountInfoStore((state) => state.available_genders)

  const showNextStep = useOnboardingStore((s) => s.showNextStep)
  const saveGender = useOnboardingStore((s) => s.saveGender)
  const currentIndex = useOnboardingStore((s) => s.currentIndex)

  const options = available_genders?.map(({ value, id }) => ({ id: value, value: id }))
  const value = available_genders?.find((item) => item.value === gender)?.id

  const [newGender, setNewGender] = useState(value)

  const continueEnabled = !!newGender

  const onContinue = async () => {
    saveGender({ value: newGender, index: currentIndex })
    showNextStep()
  }

  const handleGenderSelect = (value) => {
    setNewGender(value)
  }

  return (
    <OnboardingStep title={'Add Gender'} next={false}>
      {!available_genders && <Spinner />}
      {available_genders?.length > 0 && (
        <Select
          value={newGender || ''}
          placeholder="Choose your gender"
          options={options}
          onValueChange={handleGenderSelect}
          id={'registrationGenderDropDown'}
        />
      )}

      <Button variant="primary" onPress={onContinue} disabled={!continueEnabled}>
        Continue
      </Button>
    </OnboardingStep>
  )
}
