import { Text, YStack } from '@bp/ui'

export function OnboardingStepError({ text }) {
  return (
    <YStack fg={1} ai="center" jc="center">
      <Text variant="caption" error centered>
        {text || 'You do not have access to onboarding'}
      </Text>
    </YStack>
  )
}
