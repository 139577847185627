import { createStore } from 'zustand'
import { StreamChat } from 'stream-chat'
import { StreamVideoClient } from 'app/lib/stream/video'
import { useUserInfoStore } from 'app/store'
import API from 'app/api'
import { queryPublicRooms, queryPrivateRooms } from 'app/features/chat'

const client = StreamChat.getInstance(process.env.BP_PUBLIC_GS_KEY)
const apiKey = process.env.BP_PUBLIC_GS_KEY

const defaultProps = {
  client,
  chat: false,
  chatConnecting: false,
  chatConnected: false,
  chatConnectionError: false,
  video: false,
  videoReady: false,
  videoConnecting: false,
  videoConnected: false,
  videoConnectionError: false,
  activeChatAudioChannel: false,
  activeChatMessagingChannel: false,
  activeMessagingChannel: false,
  showMessageRequests: false,
  messagingSearchText: '',
  loading: false,
  rooms: false,
  joinCallError: false,
  joinChannelError: false,
  joinChatAudioChannelError: false,
  joinChatMessaginChannelError: false,
  disconnected: false,
  splitScreen: false,
  privateChatRooms: [],
  publicChatRooms: [],
  chatRoomsLoading: false,
}
const store = createStore()((set, get) => ({
  ...defaultProps,
  connect: async () => {
    const userInfo = useUserInfoStore.getState()
    const userId = userInfo?.id?.toString()

    try {
      const { access_token: token } = await API.stream.getChatAccessToken()

      const user = { id: userId }
      // Connect to getstream chat client
      set({ chatConnected: false, chatConnecting: true, chatConnectionError: false })
      try {
        const chatclient = new StreamChat(apiKey)
        await chatclient.connectUser(user, token)
        set({ chat: chatclient, chatConnected: true, chatConnecting: false, disconnected: false })
      } catch (error) {
        console.error('Error setting up chat client', error)
        set({ chatConnected: false, chatConnecting: false, chatConnectionError: error?.message })
      }
      // Connect to getstream video & audio client
      set({ videoConnected: false, videoConnecting: true, videoConnectionError: false })
      try {
        const videoClient = new StreamVideoClient({ apiKey, user, token })
        set({
          video: videoClient,
          videoConnected: true,
          videoConnecting: false,
          disconnected: false,
        })
      } catch (error) {
        console.error('Error setting up video client', error)
        set({ videoConnected: false, videoConnecting: false, videoConnectionError: error?.message })
      }
    } catch (error) {
      console.error('Chat access token error', error?.message)
      set({
        chatConnected: false,
        chatConnecting: false,
        chatConnectionError: error?.message,
      })
      return
    }
  },
  disconnect: async () => {
    await get().client?.disconnectUser()
    set({ ...defaultProps, disconnected: true })
  },
  joinChatAudioChannel: async (roomType: string, roomId: string, upsert = true) => {
    set({ joinChatAudioChannelError: false })
    try {
      const video = get().video
      const call = await video.call(roomType, roomId)
      await call?.join()
      set({ activeChatAudioChannel: call, joinChatAudioChannelError: false })
    } catch (error) {
      console.log('joinChatAudioChannel error', error)
      set({
        joinChatAudioChannelError:
          error?.response?.data?.message || 'Error joining video/audio room',
        activeChatAudioChannel: false,
      })
    }
  },
  joinChatMessagingChannel: async (roomType: string, roomId: string, upsert = true) => {
    set({ joinChatMessagingChannelError: false })
    try {
      const chat = get().chat
      const filter = { type: roomType, id: roomId }
      const sort = [{ last_message_at: -1 }]
      const channels = await chat.queryChannels(filter, sort, {
        watch: true,
        state: true,
      })
      const channel = channels[0]
      await channel.watch()
      set({ activeChatMessagingChannel: channel })
    } catch (error) {
      set({
        joinChatMessagingChannelError: error?.response?.data?.message || 'Error joining chatroom',
        activeChatMessagingChannel: false,
      })
    }
  },
  endChatAudioChannel: () => {
    const call = get().activeChatAudioChannel
    if (call) {
      call?.endCall()
    }
    set({ activeChatAudioChannel: false, joinChatAudioChannelError: false })
  },
  endChatMessagingChannel: async () => {
    const channel = get().activeChatMessagingChannel
    if (channel) {
      try {
        await channel?.delete()
      } catch (error) {
        console.error('endChatMessagingChannel error:', error)
      }
    }
    set({ activeChatMessagingChannel: false, joinChatMessagingChannelError: false })
  },
  endChatRoomChannels: async () => {
    const { endChatAudioChannel, endChatMessagingChannel } = get()
    try {
      await endChatAudioChannel()
      await endChatMessagingChannel()
      set({ requests: [] })
    } catch (error) {
      console.error('endChatRoomChannels error:', error)
    }
  },
  leaveChatAudioChannel: async () => {
    const call = get().activeChatAudioChannel
    if (call) {
      try {
        await call.leave()
      } catch (error) {
        console.error('leaveChatAudioChannel error:', error)
      }
    }
    set({ activeChatAudioChannel: false, joinChatAudioChannelError: false })
  },
  leaveChatMessagingChannel: async () => {
    const channel = get().activeChatMessagingChannel
    if (channel) {
      await channel.stopWatching()
    }
    set({ activeChatMessagingChannel: false, joinChatMessagingChannelError: false })
  },
  setActiveMessagingChannel: (channel) => {
    set({ activeMessagingChannel: channel })
  },
  setMessagingSearchText: (text) => {
    set({ messagingSearchText: text })
  },
  setShowMessageRequests: (value) => {
    set({ showMessageRequests: value })
  },
  setSplitScreen: (value) => {
    set({ splitScreen: value })
  },
  clear: () => {
    set({ ...defaultProps })
  },
  setPrivateChatRooms: (privateChatRooms) => {
    set({ privateChatRooms })
  },
  setPublicChatRooms: (publicChatRooms) => {
    set({ publicChatRooms })
  },
  setActiveChatAudioChannel: (channel) => {
    set({ activeChatAudioChannel: channel })
  },
  setActiveChatMessagingChannel: (channel) => {
    set({ activeChatMessagingChannel: channel })
  },
  setChatRoomsLoading: (value) => {
    set({ chatRoomsLoading: value })
  },

  fetchChatRooms: async () => {
    const { setChatRoomsLoading, setPublicChatRooms, setPrivateChatRooms, video } = get()
    setChatRoomsLoading(true)
    try {
      const publicRooms = await queryPublicRooms(video)
      setPublicChatRooms([...publicRooms])
      const privateRooms = await queryPrivateRooms(video)
      setPrivateChatRooms([...privateRooms])
    } catch (error) {
      console.error('Error fetching chat rooms', error)
    }
    setChatRoomsLoading(false)
  },
}))
export default store
