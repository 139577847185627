import { YStack, Stack } from '@bp/ui'
import React from 'react'

import { OnboardingStepsIndicator } from './OnboardingStepsIndicator'
import { useOnboardingStore } from '../store'

export function OnboardingWrapper({ children }) {
  const steps = useOnboardingStore((s) => s.steps)
  const currentStep = useOnboardingStore((s) => s.currentStep)

  return (
    <YStack fg={1} px={25} br={10} bc="$lightest">
      <YStack fg={1}>
        {steps?.length > 0 && (
          <Stack py={10}>
            <OnboardingStepsIndicator steps={steps} currentStep={currentStep} />
          </Stack>
        )}

        <Stack py={30} fs={1} fg={1}>
          <YStack fs={1} fg={1}>
            {children}
          </YStack>
        </Stack>
      </YStack>
    </YStack>
  )
}
