import { TamaguiProvider, TamaguiProviderProps, ToastProvider, ToastViewport } from '@bp/ui'
import { useColorScheme } from 'react-native'
import config from '../tamagui.config'
import { Auth0Provider } from 'app/lib/auth'
import React from 'react'
import { StreamProvider } from 'app/lib/stream/context'
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context'
import { UserCheckPoint } from 'app/features/user'
import { OnboardingCheckPoint } from 'app/features/onboarding'
import { StreamCheckPoint } from 'app/features'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

export function Provider({ children, ...rest }: Omit<TamaguiProviderProps, 'config'>) {
  const scheme = useColorScheme()
  //const theme = scheme === 'dark' ? 'dark' : 'light'
  const theme = 'dark'

  return (
    <QueryClientProvider client={queryClient}>
      <TamaguiProvider config={config} disableInjectCSS defaultTheme={theme} {...rest}>
        <SafeAreaProvider fg={1} bc="$lightest">
          <ToastProvider>
            <Auth0Provider>
              <UserCheckPoint>
                <OnboardingCheckPoint>
                  <StreamProvider>
                    <StreamCheckPoint>{children}</StreamCheckPoint>
                  </StreamProvider>
                </OnboardingCheckPoint>
              </UserCheckPoint>
            </Auth0Provider>
            <ToastViewport fd="column" b={100} l={0} r={0} />
          </ToastProvider>
        </SafeAreaProvider>
      </TamaguiProvider>
    </QueryClientProvider>
  )
}
