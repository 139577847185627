import { Text as TuiText, TextProps as TuiTextProps, styled } from 'tamagui'
import { StyledTextProps } from './types.D'

const StyledText = TuiText.styleable((props: StyledTextProps, ref) => (
  <TuiText ref={ref} {...props} />
))

export const Text = styled(StyledText, {
  name: 'Text',
  fontSize: 14,
  variants: {
    variant: {
      H1: {
        fontSize: 45,
        fontWeight: '700',
      },
      H2: {
        fontSize: 35,
        fontWeight: '700',
      },
      H3: {
        fontSize: 30,
        fontWeight: '700',
      },
      H4: {
        fontSize: 25,
        fontWeight: '700',
      },
      H5: {
        fontSize: 20,
      },
      H6: {
        fontSize: 15,
        fontWeight: '600',
      },
      subtitle: {
        fontSize: 18,
        fontWeight: '600',
      },
      menu: {
        fontSize: 18,
      },
      callout: {
        fontSize: 16,
      },
      medium: {
        fontSize: 14,
      },
      body: {
        fontSize: 15,
      },

      subhead: {
        fontSize: 15,
      },
      footnote: {
        fontSize: 12,
      },
      label: {
        fontSize: 12,
      },
      caption: {
        fontSize: 12,
      },
      caption2: {
        fontSize: 11,
      },
      caption3: {
        fontSize: 10,
      },
      caption4: {
        fontSize: 9,
      },
    },
    bold: {
      true: {
        fontWeight: '700',
      },
    },
    capitalize: {
      true: {
        textTransform: 'capitalize',
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
    underline: {
      true: {
        textDecorationLine: 'underline',
      },
    },
    error: {
      true: {
        color: '$errorDarker',
      },
    },
    success: {
      true: {
        color: '$successDarker',
      },
    },
    info: {
      true: {
        color: '$infoDarker',
      },
    },
    warning: {
      true: {
        color: '$warningDarker',
      },
    },
    centered: {
      true: {
        textAlign: 'center',
      },
    },
    link: {
      true: {
        color: '$secondaryDarker',
      },
    },
  } as const,
})
