export const ONBOARDING_DOB_TEXT1 = `What is Your Date of Birth?`
export const ONBOARDING_DOB_TEXT2 = `Please enter your date of birth and confirm you are 13 years or older`
export const ONBOARDING_DOB_BUTTON_TEXT1 = `Continue`

export const ONBOARDING_DISPLAY_NAME_TEXT1 = `Create a Display Name`
export const ONBOARDING_DISPLAY_NAME_TEXT2 = `Choose how others see your name displayed on BlackPlanet. You can always change this later.`
export const ONBOARDING_DISPLAY_NAME_TEXT3 = `Display name`
export const ONBOARDING_DISPLAY_NAME_ERROR_TEXT2 = `Display Name must be between 4 and 50 characters long`
export const ONBOARDING_DISPLAY_NAME_ERROR_TEXT3 = `The Display Name cannot have any of the following special characters: <, >, ", ', ;, %, {, or |.`
export const ONBOARDING_DISPLAY_NAME_ERROR_TEXT4 = `Display Name must be between 4 and 50 characters long`
