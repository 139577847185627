import { XStack, Logo, Logotype } from '@bp/ui'

export function OnboardingLogo() {
  return (
    <XStack jc="center" ai="center" gap="$3">
      <Logo />
      <Logotype />
    </XStack>
  )
}
